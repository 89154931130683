import {
  THANKYOU_PAGE_UPDATE_SET_SECTIONS_DATA,
  THANKYOU_PAGE_UPDATE_SET_ID,
  THANKYOU_PAGE_UPDATE_SET_SECTIONS_HAS_DATA,
  THANKYOU_PAGE_UPDATE_SET_SECTIONS_CONFIG,
} from "./actions";

const initialState = {
  id: "",
  pageType: null,
  update: {
    sectionsConfig: [],
    sectionsData: [],
  },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case THANKYOU_PAGE_UPDATE_SET_SECTIONS_CONFIG:
      return {
        ...state,
        update: {
          ...state.update,
          sectionsConfig: payload.sectionsConfig,
        },
      };
    case THANKYOU_PAGE_UPDATE_SET_SECTIONS_DATA:
      return {
        ...state,
        update: {
          ...state.update,
          sectionsData: payload.sectionsData,
        },
      };
    case THANKYOU_PAGE_UPDATE_SET_ID:
      return {
        ...state,
        id: payload.id,
        pageType: payload.pageType,
      };
    case THANKYOU_PAGE_UPDATE_SET_SECTIONS_HAS_DATA:
      return {
        ...state,
        update: {
          ...state.update,
          sectionsConfig: payload.sectionsConfig,
        },
      };
    default:
      return state;
  }
};

export default reducer;
