import { THANKYOU_PAGE_TYPES } from "ui/pages/ThankYou/constants/Thankyou.constants";

export const CONFIRMATION_DEFAULT_FIELD_VALUES = {
  [THANKYOU_PAGE_TYPES.PURCHASE.key]: {
    title: "Purchase successful!",
    description:
      "You will get the purchase confirmation and invoice in your email",
  },
  [THANKYOU_PAGE_TYPES.OPTIN.key]: {
    title: "Thank you!",
    description:
      "We are glad that you are interested in our offering. We will reach out to you soon.",
  },
};
