import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import style from "./style.module.css";
import baseStyle from "../../../style.module.css";
import classnames from "classnames";

import {
  TESTIMONIAL_TYPES,
  TESTIMONIAL_ICON_MAP,
  TEMPLATE_KEYS,
  TestimonialConfig,
} from "../../../../../constants";
import { ImageUploader } from "../../../../../widgets";

import placeholderImg from "assets/images/image-uploader-placeholder.png";
import { validURL } from "utils/validations";
import { parseYTUrl } from "utils/urlUtils";
import { IMAGE_SECTION_KEY_PATH } from "features/Common/modules/ExlyImage/constants/ExlyImage.constants";

// Labels to show for the fields with error
const errKeyLabels = {
  name: () => "Customer Name",
  content: () => "Content/Caption",
  media: (dataType) =>
    dataType === TESTIMONIAL_TYPES.VIDEO ? "Video" : "Image",
};

const formData = {
  name: {
    key: "name",
    label: "Customer Name",
  },
  title: {
    key: "title",
    label: "Testimonial Title",
  },
  content: {
    key: "content",
    label: "Content/Caption",
  },
  photo: {
    key: "cover_image",
    label: "Customer Photo",
  },
  imageMedia: {
    key: "media",
    label: "Upload Image",
  },
  videoMedia: {
    key: "media",
    label: "Video URL",
  },
};

const TestimonialModal = (props) => {
  const {
    data,
    modalState,
    setModalState,
    LoadingComponent,
    pushNotif,
    template,
  } = props;

  const [isPreviewOpen, setPreviewOpen] = useState(false);

  const isUnlock = template === TEMPLATE_KEYS.UNLOCK;

  const primaryCallback = ({ data }) => {
    let errObj = {};
    if (!data?.name?.trim()) {
      errObj.name = "Please enter a customer name";
    } else if (data.name.length < 2) {
      errObj.name = `Customer Name must be at least 2 characters long (Currently ${data.name.length})`;
    } else if (
      data.name.length > TestimonialConfig?.maxTitleLength?.[template]
    ) {
      errObj.name = `Customer Name must be less than ${TestimonialConfig?.maxTitleLength?.[template]} characters (Currently ${data.name.length})`;
    }

    if (isUnlock) {
      if (!data?.title?.trim()) {
        errObj.title = "Please enter a testimonial title";
      } else if (
        data.title.length > TestimonialConfig?.maxHeadingLength[template]
      ) {
        errObj.title = `Testimonial Title must be less than ${TestimonialConfig?.maxHeadingLength[template]} characters (Currently ${data.title.length})`;
      }
    }

    if (!data?.content?.trim()) {
      errObj.content = "Please enter a caption";
    } else if (data.content.length < 2) {
      errObj.content = `Caption must be at least 2 characters long (Currently ${data.content.length})`;
    }

    if (data.type === TESTIMONIAL_TYPES.VIDEO) {
      if (!validURL(data.media)) {
        errObj.media = "Please enter a valid video url";
      }
    } else if (data.type === TESTIMONIAL_TYPES.IMAGE) {
      if (!data.media) {
        errObj.media = "Please upload an image";
      }
    }
    const errKeys = Object.keys(errObj);

    if (errKeys.length > 0) {
      pushNotif(
        `The following fields have an error: ${errKeys
          .map((e) => errKeyLabels?.[e]?.(data.type) || e)
          .join(", ")}`
      );
    }
    setModalState((stateProp) => ({
      ...stateProp,
      error: {
        ...errObj,
      },
    }));

    return errKeys.length === 0;
  };

  useEffect(() => {
    setModalState({ data, primaryCallback });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (key, val) => {
    setModalState((stateProp) => ({
      ...stateProp,
      data: { ...stateProp?.data, [key]: val },
      error: { ...stateProp?.error, [key]: null },
    }));
  };
  const handleTypeChange = (newType) => {
    setModalState((stateProp) => ({
      ...stateProp,
      data: {
        ...stateProp.data,
        type: newType,
        media: "",
      },
      error: null,
    }));
  };

  const testimonialData = modalState?.data;

  if (!testimonialData?.type) return <LoadingComponent />;

  const isEditing = !!testimonialData?.uuid;

  return (
    <div className={style.root}>
      <div className={style.body}>
        {isEditing ? (
          <div className={classnames(baseStyle.info, "mb-2")}>
            Testimonial type cannot be changed once created
          </div>
        ) : null}
        <Tabs
          value={testimonialData?.type}
          className={style.typeSelector}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => {
            handleTypeChange(v);
          }}
          aria-label="disabled tabs example"
          variant="fullWidth"
          title={
            isEditing ? "Testimonial type cannot be changed once created" : null
          }
        >
          {Object.entries(TESTIMONIAL_TYPES).map(([typeString, typeKey]) => (
            <Tab
              key={typeKey}
              value={typeKey}
              label={typeString}
              icon={TESTIMONIAL_ICON_MAP[typeKey]()}
              disabled={isEditing}
              classes={{
                wrapper: style.typeTabWrapper,
                labelIcon: style.typeTabLabel,
              }}
            />
          ))}
        </Tabs>
        <div className={baseStyle.fieldContainer}>
          <div className={baseStyle.label}>{formData.photo.label}</div>
          <div className={baseStyle.field}>
            <ImageUploader
              uploadUrl={testimonialData?.cover_image}
              setUploadUrl={(v) => handleChange(formData.photo.key, v)}
              hoverText="Upload Image"
              fullWidth={150}
              width={80}
              height={80}
              borderRadius="100%"
              customDefaultImage={placeholderImg}
              className={style.profilePicUploader}
              query={"Profile Pic"}
              id="testimonial_modal_cover"
              uploadViaOptimization
              sectionName={IMAGE_SECTION_KEY_PATH.HOME_PAGE}
            />
          </div>
        </div>
        <div className={baseStyle.fieldContainer}>
          <div className={`${baseStyle.label} ${baseStyle.required}`}>
            {formData.name.label}
          </div>
          <div className={baseStyle.field}>
            <OutlinedInput
              className={baseStyle.input}
              value={testimonialData?.name}
              name="Name"
              onChange={(e) => {
                handleChange(formData.name.key, e.target.value);
              }}
            />
          </div>
          {testimonialData?.name?.length >
          TestimonialConfig?.maxTitleLength[template] ? (
            <div className={baseStyle.modal_error}>
              Maximum field length of{" "}
              {TestimonialConfig?.maxTitleLength[template]} reached
              <br />
              <br />
              <br />
            </div>
          ) : (
            <div className={baseStyle.modal_error}>
              {modalState?.error?.name || ""}
            </div>
          )}
        </div>
        <div className={style.divider}>
          <hr />
        </div>

        {testimonialData?.type === TESTIMONIAL_TYPES.TEXT ? null : (
          <div className={baseStyle.fieldContainer}>
            {testimonialData?.type === TESTIMONIAL_TYPES.VIDEO ? (
              <>
                <div className={`${baseStyle.label} ${baseStyle.required}`}>
                  {formData.videoMedia.label}
                </div>
                <div className={baseStyle.field}>
                  <OutlinedInput
                    className={baseStyle.input}
                    value={testimonialData?.media}
                    name="media"
                    onChange={(e) => {
                      handleChange(formData.videoMedia.key, e.target.value);
                    }}
                  />
                </div>
                {validURL(testimonialData?.media) ? (
                  <div
                    className={`${style.videoPreviewContainer} ${
                      isPreviewOpen ? style.previewOpen : ""
                    }`}
                  >
                    <div
                      className={style.previewCTA}
                      onClick={() => {
                        setPreviewOpen(!isPreviewOpen);
                      }}
                    >
                      Preview&nbsp;
                      {isPreviewOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </div>
                    {isPreviewOpen ? (
                      <ReactPlayer
                        width="100%"
                        className={style.previewVideo}
                        key={testimonialData?.media}
                        url={parseYTUrl(testimonialData?.media)}
                        controls
                      />
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : null}
            {testimonialData?.type === TESTIMONIAL_TYPES.IMAGE ? (
              <>
                <div className={`${baseStyle.label} ${baseStyle.required}`}>
                  {formData.imageMedia.label}
                </div>
                <div className={baseStyle.field}>
                  <ImageUploader
                    uploadUrl={testimonialData?.media}
                    setUploadUrl={(v) => {
                      handleChange(formData.imageMedia.key, v);
                    }}
                    hoverText="Upload Image"
                    fullWidth={600}
                    width={250}
                    height={250}
                    borderRadius="2%"
                    customDefaultImage={placeholderImg}
                    className={style.imageUploader}
                    query={"Image"}
                    id="testimonial_modal_image"
                    uploadViaOptimization
                    sectionName={IMAGE_SECTION_KEY_PATH.HOME_PAGE}
                  />
                </div>
              </>
            ) : null}
            <div className={baseStyle.modal_error}>
              {modalState?.error?.media || ""}
            </div>
          </div>
        )}

        {isUnlock ? (
          <div className="mb-3">
            <div
              className={classnames(
                baseStyle.label,
                baseStyle.required,
                "mt-3",
                "mb-1"
              )}
            >
              {formData.title.label}
            </div>
            <OutlinedInput
              placeholder="Title"
              className={baseStyle.input}
              value={testimonialData?.title}
              name="Title"
              rows={3}
              onChange={(e) => {
                handleChange(formData.title.key, e.target.value);
              }}
            />
            {testimonialData?.title?.length >
            TestimonialConfig?.maxHeadingLength[template] ? (
              <div className={baseStyle.modal_error}>
                Maximum field length of{" "}
                {TestimonialConfig?.maxHeadingLength[template]} reached
                <br />
                <br />
                <br />
              </div>
            ) : (
              <div className={baseStyle.modal_error}>
                {modalState?.error?.title || ""}
              </div>
            )}
          </div>
        ) : null}

        <div className={baseStyle.fieldContainer}>
          <div className={`${baseStyle.label} ${baseStyle.required}`}>
            {formData.content.label}
          </div>
          <div className={baseStyle.field}>
            <OutlinedInput
              className={baseStyle.input}
              value={testimonialData?.content}
              name={formData.content.key}
              multiline
              rows={3}
              onChange={(e) => {
                handleChange(formData.content.key, e.target.value);
              }}
            />
          </div>
          <div className={baseStyle.modal_error}>
            {modalState?.error?.content || ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialModal;
