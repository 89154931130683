import api from "data/APIs";
import dataProvider from "data/dataProvider";
import { put, takeEvery, call, select } from "redux-saga/effects";
import { isInteger, is_empty } from "utils/validations";
import {
  EXLY_WEBPAGE_DETAILS__FETCH_KEYWORDS,
  EXLY_WEBPAGE_DETAILS__REFETCH_LISTINGS,
  EXLY_WEBPAGE_DETAILS__REQUEST_LISTINGS,
  EXLY_WEBPAGE_DETAILS__SET_KEYWORDS,
  EXLY_WEBPAGE_DETAILS__SET_LISTINGS,
  EXLY_WEBPAGE_DETAILS__STOP_PROCESSING,
  EXLY_WEBPAGE_DETAILS__UPDATE_SKU_NAME,
  EXLY_WEBPAGE_DETAILS__REFETCH_KEYWORDS,
  EXLY_WEBPAGE_DETAILS__GET_SAVED_REORDER_TYPE,
  EXLY_WEBPAGE_DETAILS__SET_SAVED_REORDER_TYPE,
} from "./actions";

function* getReorderListings() {
  try {
    const listings = yield select((state) => state.webpage_detail.listings);
    if (is_empty(listings)) {
      const response = yield call(
        dataProvider.custom_request,
        api.get_reorder_listings
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_WEBPAGE_DETAILS__SET_LISTINGS,
          payload: response.data,
        });
      } else {
        throw `API: ${api.get_reorder_listings} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_WEBPAGE_DETAILS__SET_LISTINGS,
        payload: listings,
      });
    }
  } catch (error) {
    console.log("error", error);
    yield put({ type: EXLY_WEBPAGE_DETAILS__SET_LISTINGS, payload: null });
  } finally {
    yield put({ type: EXLY_WEBPAGE_DETAILS__STOP_PROCESSING });
  }
}

function* updateSkuName() {
  try {
    const listings = yield select((state) => state.webpage_detail.listings);
    const selected_sku = yield select(
      (state) => state.webpage_detail.selected_sku
    );
    if (!is_empty(listings) && isInteger(selected_sku)) {
      const response = yield call(
        dataProvider.custom_request,
        api.update_sku_name,
        "POST",
        {
          listing_type: parseInt(selected_sku),
          name: listings.listings_data?.sku_map?.listings[selected_sku],
        }
      );
      if (response.status !== 200) {
        throw `API: ${api.update_sku_name} FAIL`;
      }
    }
  } catch (error) {
    console.log("error", error);
  } finally {
    yield put({ type: EXLY_WEBPAGE_DETAILS__STOP_PROCESSING });
  }
}

function* fetchKeywords({ forceUpdate = false }) {
  try {
    const keywords = yield select((state) => state.webpage_detail.keywords);
    if (is_empty(keywords) || forceUpdate) {
      const response = yield call(
        dataProvider.custom_request,
        api.fetch_keywords
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_WEBPAGE_DETAILS__SET_KEYWORDS,
          payload: response.data,
        });
      } else {
        throw `API: ${api.fetch_keywords} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_WEBPAGE_DETAILS__SET_KEYWORDS,
        payload: keywords,
      });
    }
  } catch (error) {
    console.log("error", error);
    yield put({ type: EXLY_WEBPAGE_DETAILS__SET_KEYWORDS, payload: [] });
  } finally {
    yield put({ type: EXLY_WEBPAGE_DETAILS__STOP_PROCESSING });
  }
}

function* fetchSavedType() {
  try {
    const saved_reordering_type = yield select(
      (state) => state.webpage_detail.saved_reordering_type
    );
    if (!isInteger(saved_reordering_type)) {
      const response = yield call(
        dataProvider.custom_request,
        api.get_selected_reorder_type
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_WEBPAGE_DETAILS__SET_SAVED_REORDER_TYPE,
          payload: response.data.categorise_type,
        });
      } else {
        throw `API: ${api.get_selected_reorder_type} FAIL`;
      }
    }
  } catch (error) {
    console.log("error", error);
    yield put({
      type: EXLY_WEBPAGE_DETAILS__SET_SAVED_REORDER_TYPE,
      payload: null,
    });
  } finally {
    yield put({ type: EXLY_WEBPAGE_DETAILS__STOP_PROCESSING });
  }
}

function* webpageDetailsSaga() {
  yield takeEvery(EXLY_WEBPAGE_DETAILS__REQUEST_LISTINGS, getReorderListings);
  yield takeEvery(EXLY_WEBPAGE_DETAILS__UPDATE_SKU_NAME, updateSkuName);
  yield takeEvery(EXLY_WEBPAGE_DETAILS__FETCH_KEYWORDS, fetchKeywords);
  yield takeEvery(EXLY_WEBPAGE_DETAILS__REFETCH_LISTINGS, getReorderListings);
  yield takeEvery(EXLY_WEBPAGE_DETAILS__REFETCH_KEYWORDS, fetchKeywords);
  yield takeEvery(EXLY_WEBPAGE_DETAILS__GET_SAVED_REORDER_TYPE, fetchSavedType);
}

export default webpageDetailsSaga;
