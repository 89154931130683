import dataProvider from "data/dataProvider";
import api from "data/APIs";
import { is_empty } from "utils/validations";
import { HTTP_STATUS_SUCCESS_CODES, apiMethods } from "data/api.constants";

// checks for if the Upsell offering has recieved any transaction or not
// if yes then Upsell selection should be disabled
export const handleUpsellDisabled = async ({ listingUuid, onDisabledCb }) => {
  try {
    const response = await dataProvider.custom_request(
      `${api.get_listing_details}/${listingUuid}`,
      apiMethods.GET,
      {
        track_upsell_bookings: true,
      }
    );
    if (response.status === HTTP_STATUS_SUCCESS_CODES.HTTP_STATUS_OK) {
      const has_received_upsell_booking =
        response.data?.event?.has_received_upsell_booking || false;
      const shouldDisable = has_received_upsell_booking;
      if (shouldDisable) onDisabledCb(shouldDisable);
    }
  } catch (err) {
    console.log("Error at handleUpsellDisabled call for Thankyou Upsell", err);
  }
};

// Add Section Vaildator here
export const validateSectionData = (data, handleError) => {
  const tempError = {};
  const {
    upsell_offering_uuid,
    upsell_price,
    international_upsell_price,
    enable_upsell_international_price,
  } = data || {};
  if (upsell_offering_uuid) {
    if (is_empty(upsell_price)) {
      tempError.offeringPrice = true;
    }
    if (
      enable_upsell_international_price &&
      is_empty(international_upsell_price)
    ) {
      tempError.intlPrice = true;
    }
  }
  handleError && handleError(tempError);
  if (Object.keys(tempError).length > 0) return false;
  else return true;
};
