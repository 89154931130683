import { THANKYOU_PAGE_TYPES } from "ui/pages/ThankYou/constants/Thankyou.constants";

export const REDIRECTION_BTN_TYPES = {
  default: "default",
  telegram: "telegram",
  whatsapp: "whatsapp",
  custom: "custom",
  none: "none",
};

export const REDIRECTION_BTN_OPTIONS_PURCHASE = {
  [REDIRECTION_BTN_TYPES.whatsapp]: {
    id: REDIRECTION_BTN_TYPES.whatsapp,
    label: "Redirect to WhatsApp",
    value: 1,
    ctaUrlLabel: "Phone Number",
  },
  [REDIRECTION_BTN_TYPES.telegram]: {
    id: REDIRECTION_BTN_TYPES.telegram,
    label: "Redirect to Telegram",
    value: 2,
    ctaUrlLabel: "Group/ Channel Link",
  },
  [REDIRECTION_BTN_TYPES.custom]: {
    id: REDIRECTION_BTN_TYPES.custom,
    label: "Button with custom URL",
    value: 3,
    ctaUrlLabel: "Button URL",
  },
  [REDIRECTION_BTN_TYPES.default]: {
    id: REDIRECTION_BTN_TYPES.default,
    label: "Default",
    value: 4,
  },
};

export const REDIRECTION_BTN_OPTIONS_OPTIN = {
  [REDIRECTION_BTN_TYPES.whatsapp]: {
    id: REDIRECTION_BTN_TYPES.whatsapp,
    label: "Redirect to WhatsApp",
    value: 1,
    ctaUrlLabel: "Phone Number",
  },
  [REDIRECTION_BTN_TYPES.telegram]: {
    id: REDIRECTION_BTN_TYPES.telegram,
    label: "Redirect to Telegram",
    value: 2,
    ctaUrlLabel: "Group/ Channel Link",
  },
  [REDIRECTION_BTN_TYPES.custom]: {
    id: REDIRECTION_BTN_TYPES.custom,
    label: "Button with custom URL",
    value: 3,
    ctaUrlLabel: "Button URL",
  },
  [REDIRECTION_BTN_TYPES.none]: {
    id: REDIRECTION_BTN_TYPES.none,
    label: "None",
    value: 5,
  },
};

export const REDIRECTION_BTN_OPTIONS = {
  [THANKYOU_PAGE_TYPES.PURCHASE.key]: REDIRECTION_BTN_OPTIONS_PURCHASE,
  [THANKYOU_PAGE_TYPES.OPTIN.key]: REDIRECTION_BTN_OPTIONS_OPTIN,
};

export const REDIRECTION_BTN_INFO_MAP = {
  default:
    "For the default button type, your customers will see a button labeled “View Content”. Clicking this button will take them to the course page.",
};

export const REDIRECTION_AUTO_REDIRECT = {
  label: "Enable automatic redirection",
  info: "Use this option to redirect the customers to the button’s URL automatically 5 seconds after they reach the Thank You page",
};

export const REDIRECTION_URL_ON_RECIEPTS = {
  info: "Show this URL on payment receipts so that your customers can return to the link later",
};
