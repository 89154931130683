import { validateSectionData as validateConfirmationSectionData } from "../../components/Sections/Confirmation/Confirmation.utils";
import { validateSectionData as validateRedirectionSectionData } from "../../components/Sections/Redirection/Redirection.utils";
import { validateSectionData as validateUpsellSectionData } from "../../components/Sections/Upsell/Upsell.utils";
import {
  THANKYOU_PAGE_SECTION_SLUGS,
  THANKYOU_PAGE_PREVIEW_DUMMY_TITLE,
  THANKYOU_PAGE_TYPES,
} from "../../constants/Thankyou.constants";
import { CONFIRMATION_DEFAULT_FIELD_VALUES } from "../../components/Sections/Confirmation/Confirmation.constants";
import { REDIRECTION_BTN_TYPES } from "../../components/Sections/Redirection/Redirection.data";
import {
  UPSELL_FIELDS_DEFAULT_DATA,
  UPSELL_MEDIA_TYPES,
  UPSELL_NONE_OFFERING_SELECT_OPTION,
} from "../../components/Sections/Upsell/Upsell.contants";
import { getAuthData } from "utils/AuthUtil";
import { is_empty } from "utils/validations";

// validation for all sections
export const handleValidateAllSections = ({ allSectionsData }) => {
  const tempSectionsError = {};
  allSectionsData.forEach((section) => {
    if (section.data) {
      const { section_data } = section.data;
      switch (section.slug) {
        case THANKYOU_PAGE_SECTION_SLUGS.CONFIRMATION:
          if (!validateConfirmationSectionData(section_data))
            tempSectionsError.confirmation = true;
          break;
        case THANKYOU_PAGE_SECTION_SLUGS.REDIRECTION:
          if (!validateRedirectionSectionData(section_data))
            tempSectionsError.redirection = true;
          break;
        case THANKYOU_PAGE_SECTION_SLUGS.UPSELL:
          if (!validateUpsellSectionData(section_data)) {
            tempSectionsError.upsell = true;
          }
      }
    }
  });
  return tempSectionsError;
};

// setting default values for all sections if not coming from backend
export const handleDefaultSectionsData = ({ allSectionsData, pageType }) => {
  const finalSectionsData = Array.from(allSectionsData);
  for (let i = 0; i < allSectionsData.length; i++) {
    const section = finalSectionsData[i];
    let sectionData = {};
    if (is_empty(section.data.section_data)) {
      switch (section.slug) {
        case THANKYOU_PAGE_SECTION_SLUGS.CONFIRMATION:
          sectionData = {
            title: CONFIRMATION_DEFAULT_FIELD_VALUES[pageType].title,
            description:
              CONFIRMATION_DEFAULT_FIELD_VALUES[pageType].description,
          };
          section.data.section_data = sectionData;
          break;
        case THANKYOU_PAGE_SECTION_SLUGS.REDIRECTION:
          sectionData = {
            selectedBtnType:
              pageType === THANKYOU_PAGE_TYPES.PURCHASE.key
                ? REDIRECTION_BTN_TYPES.default
                : REDIRECTION_BTN_TYPES.none,
          };
          section.data.section_data = sectionData;
          break;
        case THANKYOU_PAGE_SECTION_SLUGS.UPSELL:
          sectionData = {
            upsell_offering_uuid: UPSELL_NONE_OFFERING_SELECT_OPTION.value,
            media_type: UPSELL_MEDIA_TYPES.IMAGE,
            offer_text: UPSELL_FIELDS_DEFAULT_DATA.offerText,
            title: UPSELL_FIELDS_DEFAULT_DATA.title,
            subtitle: UPSELL_FIELDS_DEFAULT_DATA.subTitle,
            description: UPSELL_FIELDS_DEFAULT_DATA.description,
            purchase_button_cta: UPSELL_FIELDS_DEFAULT_DATA.successActionText,
            cancel_button_cta: UPSELL_FIELDS_DEFAULT_DATA.failureActionText,
          };
          section.data.section_data = sectionData;
          break;
      }
    }
  }
  return finalSectionsData;
};

export const getDummyBrowserTabAssets = ({ pageType }) => {
  const auth = getAuthData();
  const tabIcon = auth.social_info.favicon || auth.display_image || "";
  let tabTitle = THANKYOU_PAGE_PREVIEW_DUMMY_TITLE[pageType];
  return {
    tabIcon,
    tabTitle,
  };
};
