import React from "react";
import Image from "@material-ui/icons/Image";
import Videocam from "@material-ui/icons/Videocam";
import Subject from "@material-ui/icons/Subject";

// Icons for templateTags
import { ReactComponent as FitnessIcon } from "assets/vectors/template_tags/fitness.svg";
import { ReactComponent as CreativeIcon } from "assets/vectors/template_tags/creative.svg";
import { ReactComponent as EducationIcon } from "assets/vectors/template_tags/education.svg";
import { ReactComponent as ServiceIcon } from "assets/vectors/template_tags/service.svg";
import { ReactComponent as OnlineStoreIcon } from "assets/vectors/template_tags/online-store.svg";

import {
  check_url_instagram,
  check_url_fb,
  check_url_youtube,
  check_url_linkedIn,
  validURL,
  check_url_twitter,
  check_url_spotify,
  check_url_tiktok,
  is_empty,
  checkYoutubeChannelUrl,
} from "../../../utils/validations";

import TestimonialModal from "./components/Sidebar/components/Testimonials/modal";
import GalleryModal from "./components/Sidebar/components/Gallery/modal";
import FeatureModal from "./components/Sidebar/components/Features/modal";
import StatisticsModal from "./components/Sidebar/components/Statistics/modal";
import { creator_miscellaneous_data_types } from "features/Common/modules/CreatorData/CreatorData.constants";

const ConfirmModal = (props) => {
  return <div>{props?.body}</div>;
};

export const MODAL_TYPES = {
  TESTIMONIALS: "TESTIMONIALS",
  GALLERY: "GALLERY",
  CONFIRM: "CONFIRM",
  FEATURES: "FEATURES",
  STATISTICS: "STATISTICS",
};

export const MODAL_ENUMS = {
  TESTIMONIALS: TestimonialModal,
  GALLERY: GalleryModal,
  CONFIRM: ConfirmModal,
  FEATURES: FeatureModal,
  STATISTICS: StatisticsModal,
};

export const DEVICES = {
  MOBILE: "MOBILE",
  DESKTOP: "DESKTOP",
};

export const TEMPLATE_KEYS = {
  CLASSIC: "classic",
  MODERN: "modern",
  ELEMENTARY: "elementary",
  UNFOLD: "unfold",
  LINKTEMP: "linktemp",
  GROWTH: "growth",
  YOGA: "yoga",
  EDUVERSE: "eduverse",
  UNLOCK: "unlock",
};

export const TESTIMONIAL_TYPES = {
  TEXT: 1,
  IMAGE: 2,
  VIDEO: 3,
};

export const GALLERY_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
};

export const TESTIMONIAL_ICON_MAP = {
  [TESTIMONIAL_TYPES.TEXT]: () => <Subject />,
  [TESTIMONIAL_TYPES.IMAGE]: () => <Image />,
  [TESTIMONIAL_TYPES.VIDEO]: () => <Videocam />,
};

export const GALLERY_ICON_MAP = {
  [GALLERY_TYPES.IMAGE]: () => <Image />,
  [GALLERY_TYPES.VIDEO]: () => <Videocam />,
};

export const GalleryConfig = {
  enableInTemplates: [
    TEMPLATE_KEYS.CLASSIC,
    TEMPLATE_KEYS.MODERN,
    TEMPLATE_KEYS.GROWTH,
    TEMPLATE_KEYS.YOGA,
    TEMPLATE_KEYS.EDUVERSE,
    TEMPLATE_KEYS.UNLOCK,
  ],

  allowFluidAspectRatio: [
    TEMPLATE_KEYS.YOGA,
    TEMPLATE_KEYS.EDUVERSE,
    TEMPLATE_KEYS.GROWTH,
  ],

  showHeading: [TEMPLATE_KEYS.UNLOCK],

  maxTitleLength: {
    [TEMPLATE_KEYS.CLASSIC]: 200,
    [TEMPLATE_KEYS.MODERN]: 200,
    [TEMPLATE_KEYS.LINKTEMP]: 200,
    [TEMPLATE_KEYS.GROWTH]: 200,
    [TEMPLATE_KEYS.YOGA]: 200,
    [TEMPLATE_KEYS.EDUVERSE]: 200,
    [TEMPLATE_KEYS.UNLOCK]: 50,
  },
  maxHeadingLength: {
    [TEMPLATE_KEYS.CLASSIC]: 200,
    [TEMPLATE_KEYS.MODERN]: 200,
    [TEMPLATE_KEYS.LINKTEMP]: 200,
    [TEMPLATE_KEYS.GROWTH]: 200,
    [TEMPLATE_KEYS.YOGA]: 200,
    [TEMPLATE_KEYS.EDUVERSE]: 200,
    [TEMPLATE_KEYS.UNLOCK]: 50,
  },
  maxDescLength: {
    [TEMPLATE_KEYS.CLASSIC]: 200,
    [TEMPLATE_KEYS.MODERN]: 200,
    [TEMPLATE_KEYS.LINKTEMP]: 200,
    [TEMPLATE_KEYS.GROWTH]: 200,
    [TEMPLATE_KEYS.YOGA]: 200,
    [TEMPLATE_KEYS.EDUVERSE]: 200,
    [TEMPLATE_KEYS.UNLOCK]: 150,
  },
};

export const TestimonialConfig = {
  enableInTemplates: [
    TEMPLATE_KEYS.CLASSIC,
    TEMPLATE_KEYS.MODERN,
    TEMPLATE_KEYS.LINKTEMP,
    TEMPLATE_KEYS.GROWTH,
    TEMPLATE_KEYS.YOGA,
    TEMPLATE_KEYS.EDUVERSE,
    TEMPLATE_KEYS.UNLOCK,
  ],
  maxTitleLength: {
    [TEMPLATE_KEYS.CLASSIC]: 200,
    [TEMPLATE_KEYS.MODERN]: 200,
    [TEMPLATE_KEYS.LINKTEMP]: 200,
    [TEMPLATE_KEYS.GROWTH]: 200,
    [TEMPLATE_KEYS.YOGA]: 200,
    [TEMPLATE_KEYS.EDUVERSE]: 200,
    [TEMPLATE_KEYS.UNLOCK]: 200,
  },
  maxHeadingLength: {
    [TEMPLATE_KEYS.UNLOCK]: 50,
  },
  maxSubtitleLength: {
    [TEMPLATE_KEYS.UNLOCK]: 200,
  },
  maxDescLength: {
    [TEMPLATE_KEYS.UNLOCK]: 500,
  },
};

export const MiscellaneousConfig = {
  enableInTemplates: [
    TEMPLATE_KEYS.CLASSIC,
    TEMPLATE_KEYS.MODERN,
    TEMPLATE_KEYS.GROWTH,
    TEMPLATE_KEYS.YOGA,
    TEMPLATE_KEYS.EDUVERSE,
    TEMPLATE_KEYS.UNLOCK,
  ],
  maxShortTitleLenght: 20,
  maxTitleLength: 50,
  maxDescLength: 500,
};

export const StatisticsConfig = {
  maxItems: {
    [TEMPLATE_KEYS.GROWTH]: 4,
    [TEMPLATE_KEYS.EDUVERSE]: 4,
    [TEMPLATE_KEYS.YOGA]: 4,
    [TEMPLATE_KEYS.UNLOCK]: 3,
  },
  maxTitleLength: {
    [TEMPLATE_KEYS.GROWTH]: 6,
    [TEMPLATE_KEYS.EDUVERSE]: 6,
    [TEMPLATE_KEYS.YOGA]: 6,
    [TEMPLATE_KEYS.UNLOCK]: 5,
  },
  maxDescLength: {
    [TEMPLATE_KEYS.GROWTH]: 50,
    [TEMPLATE_KEYS.EDUVERSE]: 20,
    [TEMPLATE_KEYS.YOGA]: 20,
    [TEMPLATE_KEYS.UNLOCK]: 20,
  },
  enableInTemplates: [
    TEMPLATE_KEYS.GROWTH,
    TEMPLATE_KEYS.EDUVERSE,
    TEMPLATE_KEYS.YOGA,
    TEMPLATE_KEYS.UNLOCK,
  ],
  samples: {
    [TEMPLATE_KEYS.GROWTH]:
      "https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/new_templates%2Fgrowth_statistics.png?alt=media&token=009d915b-579e-4e09-a8aa-657be0fd98b7",
    [TEMPLATE_KEYS.EDUVERSE]:
      "https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/new_templates%2Feduverse_statistics.png?alt=media&token=6a92edc6-2b29-4e69-8b40-06ab331cfeec",
    [TEMPLATE_KEYS.YOGA]:
      "https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/new_templates%2Fyoga_statistics.png?alt=media&token=57cb833c-6f4b-4626-a9b7-5aab15190482",
  },
  showImageInTemplates: [TEMPLATE_KEYS.GROWTH],
};

export const FeaturesConfig = {
  maxItems: {
    [TEMPLATE_KEYS.YOGA]: 3,
    [TEMPLATE_KEYS.CLASSIC]: 5,
    [TEMPLATE_KEYS.MODERN]: 5,
    [TEMPLATE_KEYS.ELEMENTARY]: 5,
    [TEMPLATE_KEYS.UNFOLD]: 5,
    [TEMPLATE_KEYS.LINKTEMP]: 5,
    [TEMPLATE_KEYS.GROWTH]: 5,
    [TEMPLATE_KEYS.YOGA]: 5,
    [TEMPLATE_KEYS.EDUVERSE]: 5,
    [TEMPLATE_KEYS.UNLOCK]: 4,
  },
  maxTitleLength: {
    [TEMPLATE_KEYS.GROWTH]: 20,
    [TEMPLATE_KEYS.EDUVERSE]: 20,
    [TEMPLATE_KEYS.UNLOCK]: 50,
  },
  maxDescLength: {
    [TEMPLATE_KEYS.CLASSIC]: 300,
    [TEMPLATE_KEYS.MODERN]: 300,
    [TEMPLATE_KEYS.ELEMENTARY]: 300,
    [TEMPLATE_KEYS.UNFOLD]: 300,
    [TEMPLATE_KEYS.LINKTEMP]: 300,
    [TEMPLATE_KEYS.GROWTH]: 300,
    [TEMPLATE_KEYS.YOGA]: 300,
    [TEMPLATE_KEYS.EDUVERSE]: 300,
    [TEMPLATE_KEYS.UNLOCK]: 500,
  },
  enableInTemplates: [
    TEMPLATE_KEYS.GROWTH,
    TEMPLATE_KEYS.EDUVERSE,
    TEMPLATE_KEYS.YOGA,
    TEMPLATE_KEYS.UNLOCK,
  ],
  samples: {
    [TEMPLATE_KEYS.GROWTH]: `https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/new_templates%2Fgrowth_features.png?alt=media&token=6b29e6c8-d584-4724-8450-21b7b9bbd193`,
    [TEMPLATE_KEYS.EDUVERSE]: `https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/new_templates%2Feduverse_features.png?alt=media&token=97c071a8-9e9f-4f87-b89d-d2aee708b1df`,
    [TEMPLATE_KEYS.YOGA]: `https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/new_templates%2Fyoga_features.png?alt=media&token=b45e9996-0c5d-4acf-9142-3f063a0a4fff`,
  },
  enableGetInTouch: [TEMPLATE_KEYS.UNLOCK],

  showImageInTemplates: [TEMPLATE_KEYS.YOGA],
  showCoverImageInTemplates: [TEMPLATE_KEYS.YOGA],
  showDescInTemplates: [TEMPLATE_KEYS.EDUVERSE, TEMPLATE_KEYS.UNLOCK],
  showSectionHeading: [
    TEMPLATE_KEYS.CLASSIC,
    TEMPLATE_KEYS.MODERN,
    TEMPLATE_KEYS.ELEMENTARY,
    TEMPLATE_KEYS.UNFOLD,
    TEMPLATE_KEYS.LINKTEMP,
    TEMPLATE_KEYS.GROWTH,
    TEMPLATE_KEYS.YOGA,
    TEMPLATE_KEYS.EDUVERSE,
  ],
};

export const AppearanceConfig = {
  showBgSelector: [TEMPLATE_KEYS.UNFOLD, TEMPLATE_KEYS.LINKTEMP],
  allowColorAsBg: [TEMPLATE_KEYS.UNFOLD],
};

export const SidebarConfig = {
  showGetInTouchSection: [TEMPLATE_KEYS.UNFOLD, TEMPLATE_KEYS.LINKTEMP],
};

export const TemplateConfig = {
  [TEMPLATE_KEYS.CLASSIC]: {
    coverImage: {
      width: 2000,
      height: 500,
      aspect_ratio: 2000 / 500,
    },
    introVideo: true,
  },
  [TEMPLATE_KEYS.MODERN]: {
    coverImage: {
      width: 2160,
      height: 1170,
      aspect_ratio: 2160 / 1170,
    },
  },
  [TEMPLATE_KEYS.ELEMENTARY]: {
    coverImage: {
      width: 2160,
      height: 1170,
      aspect_ratio: 2160 / 1170,
    },
  },
  [TEMPLATE_KEYS.UNFOLD]: {
    coverImage: {
      width: 2160,
      height: 1170,
      aspect_ratio: 2160 / 1170,
    },
    introVideo: true,
  },
  [TEMPLATE_KEYS.LINKTEMP]: {
    coverImage: {
      width: 2160,
      height: 1170,
      aspect_ratio: 2160 / 1170,
    },
    introVideo: true,
  },
  [TEMPLATE_KEYS.GROWTH]: {
    coverImage: {
      width: 2160,
      height: 1170,
      aspect_ratio: 2160 / 1170,
    },
  },
  [TEMPLATE_KEYS.EDUVERSE]: {
    coverImage: {
      width: 2160,
      height: 939,
      aspect_ratio: 2160 / 939,
    },
  },
  [TEMPLATE_KEYS.YOGA]: {
    coverImage: {
      width: 2255,
      height: 1170,
      aspect_ratio: 2255 / 1170,
    },
  },
  [TEMPLATE_KEYS.UNLOCK]: {
    coverImage: {
      width: 2160,
      height: 939,
      aspect_ratio: 2255 / 1170,
    },
    mobileCoverImage: {
      width: 750,
      height: 1120,
      aspect_ratio: 750 / 1120,
    },
  },
};

export const OfferingsSectionConfig = {
  enableInTemplates: [TEMPLATE_KEYS.UNLOCK],
};

export const IntroVideoConfig = {
  enableInTemplates: [TEMPLATE_KEYS.UNLOCK],
};

export const AboutYouConfig = {
  enableInTemplates: [
    TEMPLATE_KEYS.CLASSIC,
    TEMPLATE_KEYS.MODERN,
    TEMPLATE_KEYS.ELEMENTARY,
    TEMPLATE_KEYS.UNFOLD,
    TEMPLATE_KEYS.LINKTEMP,
    TEMPLATE_KEYS.GROWTH,
    TEMPLATE_KEYS.YOGA,
    TEMPLATE_KEYS.EDUVERSE,
  ],
};

export const ContactDetailsConfig = {
  enableInTemplates: [
    TEMPLATE_KEYS.CLASSIC,
    TEMPLATE_KEYS.MODERN,
    TEMPLATE_KEYS.ELEMENTARY,
    TEMPLATE_KEYS.UNFOLD,
    TEMPLATE_KEYS.LINKTEMP,
    TEMPLATE_KEYS.GROWTH,
    TEMPLATE_KEYS.YOGA,
    TEMPLATE_KEYS.EDUVERSE,
    TEMPLATE_KEYS.UNLOCK,
  ],
};

export const AdditionalPointersConfig = {
  enableInTemplates: [TEMPLATE_KEYS.UNLOCK],
  maxTitleLength: {
    [TEMPLATE_KEYS.UNLOCK]: 20,
  },
  maxHeadingLength: {
    [TEMPLATE_KEYS.UNLOCK]: 50,
  },
  maxDescLength: {
    [TEMPLATE_KEYS.UNLOCK]: 500,
  },
};

export const BlogsConfig = {
  enableInTemplates: [TEMPLATE_KEYS.UNLOCK],
};

export const TeamConfig = {
  enableInTemplates: [TEMPLATE_KEYS.UNLOCK],
};

export const AboutSectionConfig = {
  enableInTemplates: [TEMPLATE_KEYS.UNLOCK],
  maxDescLength: {
    [TEMPLATE_KEYS.UNLOCK]: 500,
  },
};

// It is important that these entires SIDEBAR_KEYS and SIDEBAR_SECTIONS
// in remain here in the order the section will be rendered.
export const SIDEBAR_KEYS = {
  SUBDOMAIN: "SUBDOMAIN",
  HEADER: "HEADER",
  COVER: "COVER",
  OFFERINGS_SECTION: "OFFERINGS_SECTION",
  INTRO_VIDEO: "INTRO_VIDEO",
  ABOUT_YOU: "ABOUT_YOU",
  ABOUT_SECTION: "ABOUT_SECTION",
  SOCIAL: "SOCIAL",
  AWARDS: "AWARDS",
  BRAND_COLLABORATIONS: "BRAND_COLLABORATIONS",
  MEDIA_MENTIONS: "MEDIA_MENTIONS",
  CONTACT_DETAILS: "CONTACT_DETAILS",
  TESTIMONIALS: "TESTIMONIALS",
  GALLERY: "GALLERY",
  FEATURES: "FEATURES",
  ADDITIONAL_POINTERS: "ADDITIONAL_POINTERS",
  BLOGS: "BLOGS",
  TEAMS: "TEAMS",
  STATISTICS: "STATISTICS",
  LOGO: "LOGO",
  COLORS: "COLORS",
  CUSTOM_FOOTER: "CUSTOM_FOOTER",
};

export const TEMPLATE_SIDE_BAR_ORDER = {
  [TEMPLATE_KEYS.CLASSIC]: [
    SIDEBAR_KEYS.SUBDOMAIN,
    SIDEBAR_KEYS.HEADER,
    SIDEBAR_KEYS.COVER,
    SIDEBAR_KEYS.ABOUT_YOU,
    SIDEBAR_KEYS.SOCIAL,
    SIDEBAR_KEYS.AWARDS,
    SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    SIDEBAR_KEYS.MEDIA_MENTIONS,
    SIDEBAR_KEYS.CONTACT_DETAILS,
    SIDEBAR_KEYS.TESTIMONIALS,
    SIDEBAR_KEYS.GALLERY,
    SIDEBAR_KEYS.FEATURES,
    SIDEBAR_KEYS.STATISTICS,
    SIDEBAR_KEYS.LOGO,
    SIDEBAR_KEYS.COLORS,
    SIDEBAR_KEYS.CUSTOM_FOOTER,
  ],
  [TEMPLATE_KEYS.MODERN]: [
    SIDEBAR_KEYS.SUBDOMAIN,
    SIDEBAR_KEYS.HEADER,
    SIDEBAR_KEYS.COVER,
    SIDEBAR_KEYS.ABOUT_YOU,
    SIDEBAR_KEYS.SOCIAL,
    SIDEBAR_KEYS.AWARDS,
    SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    SIDEBAR_KEYS.MEDIA_MENTIONS,
    SIDEBAR_KEYS.CONTACT_DETAILS,
    SIDEBAR_KEYS.TESTIMONIALS,
    SIDEBAR_KEYS.GALLERY,
    SIDEBAR_KEYS.FEATURES,
    SIDEBAR_KEYS.STATISTICS,
    SIDEBAR_KEYS.LOGO,
    SIDEBAR_KEYS.COLORS,
    SIDEBAR_KEYS.CUSTOM_FOOTER,
  ],
  [TEMPLATE_KEYS.ELEMENTARY]: [
    SIDEBAR_KEYS.SUBDOMAIN,
    SIDEBAR_KEYS.HEADER,
    SIDEBAR_KEYS.COVER,
    SIDEBAR_KEYS.ABOUT_YOU,
    SIDEBAR_KEYS.SOCIAL,
    SIDEBAR_KEYS.AWARDS,
    SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    SIDEBAR_KEYS.MEDIA_MENTIONS,
    SIDEBAR_KEYS.CONTACT_DETAILS,
    SIDEBAR_KEYS.TESTIMONIALS,
    SIDEBAR_KEYS.GALLERY,
    SIDEBAR_KEYS.FEATURES,
    SIDEBAR_KEYS.STATISTICS,
    SIDEBAR_KEYS.LOGO,
    SIDEBAR_KEYS.COLORS,
    SIDEBAR_KEYS.CUSTOM_FOOTER,
  ],
  [TEMPLATE_KEYS.UNFOLD]: [
    SIDEBAR_KEYS.SUBDOMAIN,
    SIDEBAR_KEYS.HEADER,
    SIDEBAR_KEYS.COVER,
    SIDEBAR_KEYS.ABOUT_YOU,
    SIDEBAR_KEYS.SOCIAL,
    SIDEBAR_KEYS.AWARDS,
    SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    SIDEBAR_KEYS.MEDIA_MENTIONS,
    SIDEBAR_KEYS.CONTACT_DETAILS,
    SIDEBAR_KEYS.TESTIMONIALS,
    SIDEBAR_KEYS.GALLERY,
    SIDEBAR_KEYS.FEATURES,
    SIDEBAR_KEYS.STATISTICS,
    SIDEBAR_KEYS.LOGO,
    SIDEBAR_KEYS.COLORS,
    SIDEBAR_KEYS.CUSTOM_FOOTER,
  ],
  [TEMPLATE_KEYS.LINKTEMP]: [
    SIDEBAR_KEYS.SUBDOMAIN,
    SIDEBAR_KEYS.HEADER,
    SIDEBAR_KEYS.COVER,
    SIDEBAR_KEYS.ABOUT_YOU,
    SIDEBAR_KEYS.SOCIAL,
    SIDEBAR_KEYS.AWARDS,
    SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    SIDEBAR_KEYS.MEDIA_MENTIONS,
    SIDEBAR_KEYS.CONTACT_DETAILS,
    SIDEBAR_KEYS.TESTIMONIALS,
    SIDEBAR_KEYS.GALLERY,
    SIDEBAR_KEYS.FEATURES,
    SIDEBAR_KEYS.STATISTICS,
    SIDEBAR_KEYS.LOGO,
    SIDEBAR_KEYS.COLORS,
    SIDEBAR_KEYS.CUSTOM_FOOTER,
  ],
  [TEMPLATE_KEYS.GROWTH]: [
    SIDEBAR_KEYS.SUBDOMAIN,
    SIDEBAR_KEYS.HEADER,
    SIDEBAR_KEYS.COVER,
    SIDEBAR_KEYS.ABOUT_YOU,
    SIDEBAR_KEYS.SOCIAL,
    SIDEBAR_KEYS.AWARDS,
    SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    SIDEBAR_KEYS.MEDIA_MENTIONS,
    SIDEBAR_KEYS.CONTACT_DETAILS,
    SIDEBAR_KEYS.TESTIMONIALS,
    SIDEBAR_KEYS.GALLERY,
    SIDEBAR_KEYS.FEATURES,
    SIDEBAR_KEYS.STATISTICS,
    SIDEBAR_KEYS.LOGO,
    SIDEBAR_KEYS.COLORS,
    SIDEBAR_KEYS.CUSTOM_FOOTER,
  ],
  [TEMPLATE_KEYS.YOGA]: [
    SIDEBAR_KEYS.SUBDOMAIN,
    SIDEBAR_KEYS.HEADER,
    SIDEBAR_KEYS.COVER,
    SIDEBAR_KEYS.ABOUT_YOU,
    SIDEBAR_KEYS.SOCIAL,
    SIDEBAR_KEYS.AWARDS,
    SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    SIDEBAR_KEYS.MEDIA_MENTIONS,
    SIDEBAR_KEYS.CONTACT_DETAILS,
    SIDEBAR_KEYS.TESTIMONIALS,
    SIDEBAR_KEYS.GALLERY,
    SIDEBAR_KEYS.FEATURES,
    SIDEBAR_KEYS.STATISTICS,
    SIDEBAR_KEYS.LOGO,
    SIDEBAR_KEYS.COLORS,
    SIDEBAR_KEYS.CUSTOM_FOOTER,
  ],
  [TEMPLATE_KEYS.EDUVERSE]: [
    SIDEBAR_KEYS.SUBDOMAIN,
    SIDEBAR_KEYS.HEADER,
    SIDEBAR_KEYS.COVER,
    SIDEBAR_KEYS.ABOUT_YOU,
    SIDEBAR_KEYS.SOCIAL,
    SIDEBAR_KEYS.AWARDS,
    SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    SIDEBAR_KEYS.MEDIA_MENTIONS,
    SIDEBAR_KEYS.CONTACT_DETAILS,
    SIDEBAR_KEYS.TESTIMONIALS,
    SIDEBAR_KEYS.GALLERY,
    SIDEBAR_KEYS.FEATURES,
    SIDEBAR_KEYS.STATISTICS,
    SIDEBAR_KEYS.LOGO,
    SIDEBAR_KEYS.COLORS,
    SIDEBAR_KEYS.CUSTOM_FOOTER,
  ],
  [TEMPLATE_KEYS.UNLOCK]: [
    SIDEBAR_KEYS.SUBDOMAIN,
    SIDEBAR_KEYS.HEADER,
    SIDEBAR_KEYS.COVER,
    SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    SIDEBAR_KEYS.OFFERINGS_SECTION,
    SIDEBAR_KEYS.INTRO_VIDEO,
    SIDEBAR_KEYS.ABOUT_SECTION,
    SIDEBAR_KEYS.SOCIAL,
    SIDEBAR_KEYS.FEATURES,
    SIDEBAR_KEYS.ADDITIONAL_POINTERS,
    SIDEBAR_KEYS.CONTACT_DETAILS,
    SIDEBAR_KEYS.TESTIMONIALS,
    SIDEBAR_KEYS.BLOGS,
    SIDEBAR_KEYS.TEAMS,
    SIDEBAR_KEYS.GALLERY,
    SIDEBAR_KEYS.STATISTICS,
    SIDEBAR_KEYS.LOGO,
    SIDEBAR_KEYS.COLORS,
    SIDEBAR_KEYS.CUSTOM_FOOTER,
  ],
};

export const getTemplateSideBarkeys = (template) => {
  let templateOrder = {};
  if (TEMPLATE_SIDE_BAR_ORDER[template]) {
    TEMPLATE_SIDE_BAR_ORDER[template].forEach((value) => {
      templateOrder[value] = SIDEBAR_KEYS[value];
    });
  }
  return templateOrder;
};

export const TABS = {
  MY_PAGE: "MY_PAGE",
  APPEARANCE: "APPEARANCE",
  REORDER: "REORDER",
};

export const SIDEBAR_TAB_LABELS = {
  [TABS.MY_PAGE]: "My Page",
  [TABS.APPEARANCE]: "Appearance",
  [TABS.REORDER]: "Reorder Offerings",
};

export const REORDERING = {
  types: [
    {
      title:
        "Basic Default Categorization: All offerings are listed in order of their upcoming slot dates",
      id: "basic",
      category_types: [4],
    },
    {
      title:
        "Create your custom keywords and categorize your offerings based on them (Example - Dance, Music, Yoga, etc.)",
      id: "keyword_based",
      category_types: [3, 5],
    },
    {
      title:
        "Categorize according to Offering Type (Example - Workshops, Pre-recorded courses, Appointments, etc.)",
      id: "listing_based",
      category_types: [1, 2],
    },
  ],
  basic_type: "basic",
  lisitng_type: "listing_based",
  keyword_type: "keyword_based",
  category_type: {
    no_categorisation: 4,
    sku_type: 1,
    sku_keyword_listing_type: 2,
    keyword_sku_listing_type: 3,
    keyword_type: 5,
  },
};

export const SIDEBAR_SECTIONS = {
  [SIDEBAR_KEYS.SUBDOMAIN]: {
    key: SIDEBAR_KEYS.SUBDOMAIN,
    title: "Your Website Address",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.MEDIA_MENTIONS]: {
    key: SIDEBAR_KEYS.MEDIA_MENTIONS,
    title: "Media mentions",
    location: TABS.MY_PAGE,
    hideMainCta: true,
  },
  [SIDEBAR_KEYS.BRAND_COLLABORATIONS]: {
    key: SIDEBAR_KEYS.BRAND_COLLABORATIONS,
    title: ({ templateName }) =>
      [TEMPLATE_KEYS.UNLOCK].includes(templateName)
        ? "Featured In"
        : "Brand Collaborations",
    location: TABS.MY_PAGE,
    hideMainCta: true,
  },
  [SIDEBAR_KEYS.AWARDS]: {
    key: SIDEBAR_KEYS.AWARDS,
    title: "Awards/certifications",
    location: TABS.MY_PAGE,
    hideMainCta: true,
  },
  [SIDEBAR_KEYS.HEADER]: {
    key: SIDEBAR_KEYS.HEADER,
    title: "Header",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.COVER]: {
    key: SIDEBAR_KEYS.COVER,
    title: ({ templateName }) =>
      SidebarConfig.showGetInTouchSection.includes(templateName)
        ? "Get In Touch Section"
        : "Cover image, Cover text & Button",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.OFFERINGS_SECTION]: {
    key: SIDEBAR_KEYS.OFFERINGS_SECTION,
    title: "Offerings Section",
    location: TABS.MY_PAGE,
    hideMainCta: true,
  },
  [SIDEBAR_KEYS.INTRO_VIDEO]: {
    key: SIDEBAR_KEYS.INTRO_VIDEO,
    title: "Intro Video",
    location: TABS.MY_PAGE,
    hideMainCta: true,
  },
  [SIDEBAR_KEYS.ABOUT_YOU]: {
    key: SIDEBAR_KEYS.ABOUT_YOU,
    title: ({ templateName }) =>
      [TEMPLATE_KEYS.UNLOCK].includes(templateName)
        ? "About Section"
        : "About Me",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.ABOUT_SECTION]: {
    key: SIDEBAR_KEYS.ABOUT_SECTION,
    title: "About Me Section",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.SOCIAL]: {
    key: SIDEBAR_KEYS.SOCIAL,
    title: "Social Media Links",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.CONTACT_DETAILS]: {
    key: SIDEBAR_KEYS.CONTACT_DETAILS,
    title: "Contact Details",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.GALLERY]: {
    key: SIDEBAR_KEYS.GALLERY,
    title: ({ templateName }) =>
      [TEMPLATE_KEYS.CLASSIC].includes(templateName) ? "Gallery" : "Portfolio",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.TESTIMONIALS]: {
    key: SIDEBAR_KEYS.TESTIMONIALS,
    title: "Testimonials",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.FEATURES]: {
    key: SIDEBAR_KEYS.FEATURES,
    title: "Top Features",
    location: TABS.MY_PAGE,
  },
  [SIDEBAR_KEYS.ADDITIONAL_POINTERS]: {
    key: SIDEBAR_KEYS.ADDITIONAL_POINTERS,
    title: "Additional Pointers",
    location: TABS.MY_PAGE,
    hideMainCta: true,
    tooltip:
      "Use this section to add any additional points that you’d like to share with your audience. It can be regarding anything.",
  },
  [SIDEBAR_KEYS.BLOGS]: {
    key: SIDEBAR_KEYS.BLOGS,
    title: "Blogs",
    location: TABS.MY_PAGE,
    hideMainCta: true,
  },
  [SIDEBAR_KEYS.TEAMS]: {
    key: SIDEBAR_KEYS.TEAMS,
    title: "Team",
    location: TABS.MY_PAGE,
    hideMainCta: true,
  },
  [SIDEBAR_KEYS.STATISTICS]: {
    key: SIDEBAR_KEYS.STATISTICS,
    title: ({ templateName }) =>
      [TEMPLATE_KEYS.GROWTH].includes(templateName)
        ? "Company Matrix"
        : "Strength in Numbers",
    location: TABS.MY_PAGE,
  },

  [SIDEBAR_KEYS.LOGO]: {
    key: SIDEBAR_KEYS.LOGO,
    title: "Logo And Branding",
    location: TABS.APPEARANCE,
  },
  [SIDEBAR_KEYS.COLORS]: {
    key: SIDEBAR_KEYS.COLORS,
    title: "Color Palette",
    location: TABS.APPEARANCE,
  },
  [SIDEBAR_KEYS.CUSTOM_FOOTER]: {
    key: SIDEBAR_KEYS.CUSTOM_FOOTER,
    title: "Custom Footer",
    location: TABS.MY_PAGE,
    hideMainCta: true,
    isWhitelabelFeature: true,
  },
};

export const SOCIAL_KEYS = {
  INSTAGRAM: "INSTAGRAM",
  FACEBOOK: "FACEBOOK",
  YOUTUBE: "YOUTUBE",
  LINKEDIN: "LINKEDIN",
  WEBSITE: "WEBSITE",
  TWITTER: "TWITTER",
  SPOTIFY: "SPOTIFY",
  TIKTOK: "TIKTOK",
};

export const SOCIAL = {
  [SOCIAL_KEYS.INSTAGRAM]: {
    key: SOCIAL_KEYS.INSTAGRAM,
    accessor: "instagram", // accessor key in the state and error object
    color: "#D1006B",
    icon: require("assets/vectors/social/webpage_creation/instagram.svg"),
    title: "Enter your Instagram username",
    analyticsKey: "instaProfile",
    onlyUsername: true, // Whether the input will only be of the username instead the whole url
    validation: check_url_instagram,
  },
  [SOCIAL_KEYS.FACEBOOK]: {
    key: SOCIAL_KEYS.FACEBOOK,
    accessor: "facebook_name",
    color: "#1977F3",
    icon: require("assets/vectors/social/webpage_creation/facebook.svg"),
    title: "Enter your Facebook profile or page link",
    analyticsKey: "facebookProfile",
    validation: check_url_fb,
  },
  [SOCIAL_KEYS.YOUTUBE]: {
    key: SOCIAL_KEYS.YOUTUBE,
    accessor: "youtube",
    color: "#C62424",
    icon: require("assets/vectors/social/webpage_creation/youtube.svg"),
    title: "Enter your Youtube channel or video link",
    analyticsKey: "youtubeChannel",
    validation: (value) => {
      let error;
      error = check_url_youtube(value);
      if (is_empty(error)) {
        return;
      }
      error = checkYoutubeChannelUrl(value);
      return error;
    },
  },
  [SOCIAL_KEYS.LINKEDIN]: {
    key: SOCIAL_KEYS.LINKEDIN,
    accessor: "linkedin",
    color: "#006699",
    icon: require("assets/vectors/social/webpage_creation/linkedin.svg"),
    title: "Enter your Linkedin url",
    analyticsKey: "linkedIn",
    validation: check_url_linkedIn,
  },
  [SOCIAL_KEYS.WEBSITE]: {
    key: SOCIAL_KEYS.WEBSITE,
    accessor: "website_url",
    color: "#493AB1",
    icon: require("assets/vectors/social/webpage_creation/website.svg"),
    title: "Enter your website link",
    analyticsKey: "website",
    validation: (data) =>
      data.length === 0 || validURL(data)
        ? null
        : "Invalid Website URL (social media link section)",
  },
  [SOCIAL_KEYS.TWITTER]: {
    key: SOCIAL_KEYS.TWITTER,
    accessor: "twitter",
    color: "#00ACEE",
    icon: require("assets/vectors/social/webpage_creation/twitter.svg"),
    title: "Enter your Twitter handle",
    analyticsKey: "twitter",
    onlyUsername: true,
    validation: check_url_twitter,
  },
  [SOCIAL_KEYS.SPOTIFY]: {
    key: SOCIAL_KEYS.SPOTIFY,
    accessor: "spotify",
    color: "#1DB954",
    icon: require("assets/vectors/social/webpage_creation/spotify.svg"),
    title: "Enter your Spotify url",
    analyticsKey: "spotifyLink",
    validation: check_url_spotify,
  },
  [SOCIAL_KEYS.TIKTOK]: {
    key: SOCIAL_KEYS.TIKTOK,
    accessor: "tiktok",
    color: "#EE1D52",
    icon: require("assets/vectors/social/webpage_creation/tiktok.svg"),
    title: "Enter your TikTok url",
    analyticsKey: "tiktokLink",
    validation: check_url_tiktok,
  },
};

export const CONTACT_MODE = {
  DESELECT: "",
  NONE: "none",
  WHATSAPP: "Whatsapp",
  TELEGRAM: "Telegram",
  ONLY_BOOKED_USER: "only_booked_user",
};

export const TEMPLATE_SELECT_TAB_KEYS = {
  ALL: "all",
  RECOMMENDED: "recommended",
  POPULAR: "popular",
};

export const TEMPLATE_SELECT_TABS = (styles = {}) => [
  {
    label: (
      <>
        Recommended
        <div className={styles.rightBorder} />
      </>
    ),
    value: TEMPLATE_SELECT_TAB_KEYS.RECOMMENDED,
  },
  { label: "All templates", value: TEMPLATE_SELECT_TAB_KEYS.ALL },
  { label: "Most popular", value: TEMPLATE_SELECT_TAB_KEYS.POPULAR },
];

export const TEMPLATE_TAGS = [
  {
    slug: "fitness",
    text: "Fitness",
    icon: FitnessIcon,
  },
  {
    slug: "creative",
    text: "Creative",
    icon: CreativeIcon,
  },
  {
    slug: "education",
    text: "Education",
    icon: EducationIcon,
  },
  {
    slug: "service",
    text: "Service",
    icon: ServiceIcon,
  },
  {
    slug: "online-store",
    text: "Online Store",
    icon: OnlineStoreIcon,
  },
];

export const getDefaultBgImage = (templateKey) =>
  `https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/TemplateSelection%2Fdefault_backdrop_images%2F${templateKey}.jpg?alt=media`;

export const TEMPLATE_PREVIEW_URLS = {
  [TEMPLATE_KEYS.CLASSIC]: "sarahpalson.myscoot.com",
  [TEMPLATE_KEYS.ELEMENTARY]: "kai.myscoot.com",
  [TEMPLATE_KEYS.MODERN]: "emilemonet.myscoot.com",
  [TEMPLATE_KEYS.UNFOLD]: "kohlmont.myscoot.com",
  [TEMPLATE_KEYS.EDUVERSE]: "moziar.myscoot.com",
  [TEMPLATE_KEYS.YOGA]: "yoga.myscoot.com",
  [TEMPLATE_KEYS.LINKTEMP]: "petalninja.exlyapp.com",
  [TEMPLATE_KEYS.GROWTH]: "stockschool.myscoot.com",
  [TEMPLATE_KEYS.UNLOCK]: "halanomit.exlyapp.com",
};

export const DEFAULT_TEMPLATE_KEYS = [TEMPLATE_KEYS.MODERN, "modern-red"];

export const BrandCollabrationLabels = {
  [TEMPLATE_KEYS.UNLOCK]: {
    label: "As Featured In",
    modal: {
      modalTitle: "Add feature",
      logoTitle: "Media logo",
      logoTextLabel: "Name of the organization",
    },
  },
  default: {
    label: "Brand collaborations",
    modal: {
      modalTitle: "Add a brand collaboration",
      logoTitle: "Brand's logo",
      logoTextLabel: "Name of the brand",
    },
  },
};

export const miscellaneousMapConfig = [
  {
    type: creator_miscellaneous_data_types.AWARDS_CERTIFICATES,
    key: "awards",
  },
  {
    type: creator_miscellaneous_data_types.BRAND_COLLABORATION,
    key: "brands",
  },
  {
    type: creator_miscellaneous_data_types.MEDIA_MENTIONS,
    key: "media",
  },
  {
    type: creator_miscellaneous_data_types.OFFERING_SECTION,
    key: "offeringsSection",
  },
  {
    type: creator_miscellaneous_data_types.INTRO_VIDEO,
    key: "introVideo",
  },
  {
    type: creator_miscellaneous_data_types.BLOGS,
    key: "blogs",
  },
  {
    type: creator_miscellaneous_data_types.TEAMS,
    key: "teams",
  },
  {
    type: creator_miscellaneous_data_types.ADDITIONAL_POINTERS,
    key: "additionalPointers",
  },
  {
    type: creator_miscellaneous_data_types.ABOUT_SECTION,
    key: "aboutSection",
  },
  {
    type: creator_miscellaneous_data_types.CUSTOM_FOOTER,
    key: "customFooter",
  },
];

export const newTagTemplatesList = [];

export const SHOW_CONTENT_LENGTH = 85;
export const MAX_STATISTICS_TITLE = 200;

export const DEFAULT_COVER_IMAGE = {
  [TEMPLATE_KEYS.LINKTEMP]:
    "https://lpcdn-preprod.myscoot.com/public-images/default_bg.webp",
  [TEMPLATE_KEYS.YOGA]:
    "https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/yoga_cover.webp?alt=media&token=a6e6a0f4-c4fc-4136-a7e3-54f47887a703",
  [TEMPLATE_KEYS.EDUVERSE]: `https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/wp7911176-online-school-wallpapers.jpg?alt=media&token=b8c15d43-fb57-4aee-bd71-ca10ddd32994`,
  [TEMPLATE_KEYS.GROWTH]: `https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/growth_cover.jpg?alt=media&token=c2ac9daa-38d1-4638-9ec8-2375d97cf881`,
  [TEMPLATE_KEYS.UNLOCK]:
    "https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/exly%2Fe%2Fpng%3Bbasj3slAa1q?alt=media&token=38613863-8ebe-4d05-bc5d-29f5b47ba45c",
};

export const DEFAULT_INTRO_VIDEO_BG = {
  [TEMPLATE_KEYS.UNLOCK]:
    "https://images.unsplash.com/photo-1515204230490-1ad00b70ed3d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80",
};

export const DEFAULT_STATS_BG = {
  [TEMPLATE_KEYS.UNLOCK]: DEFAULT_COVER_IMAGE[TEMPLATE_KEYS.UNLOCK],
};

export const DEFAULT_IMAGE_PARAMS = {
  [TEMPLATE_KEYS.UNLOCK]: {
    width: 200,
  },
};

export const OFFERING_SECTION_FORM_DATA = {
  [TEMPLATE_KEYS.UNLOCK]: {
    heading: {
      key: "heading",
      label: "Heading",
    },
    description: {
      key: "description",
      label: "Description",
    },
  },
};

export const template_field_keys = {
  social_info: {
    name: "social_info",
  },
  about_section_title: {
    name: "about_section_title",
  },
  hero_section_title: {
    name: "hero_section_title",
  },
  favicon: {
    name: "favicon",
  },
  logo_image: {
    name: "logo_image",
  },
};

export const MANAGE_WEBPAGE_LEARN_MORE_HREFS = {
  MANAGE_WEBPAGE: "https://support.exlyapp.com/support/solutions/84000149808",
};
