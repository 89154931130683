import React, { useEffect } from "react";

import { OutlinedInput } from "@material-ui/core";
import Info from "@material-ui/icons/Info";

import style from "./style.module.css";
import baseStyle from "../../../style.module.css";

import { StatisticsConfig } from "../../../../../constants";
import { ImageUploader } from "../../../../../widgets";
import { IMAGE_SECTION_KEY_PATH } from "features/Common/modules/ExlyImage/constants/ExlyImage.constants";

const errKeyLabels = {
  value: () => "Statistic Number",
  description: () => "Description",
  media: () => "Image",
};

const formData = {
  value: {
    key: "value",
    label: "Number",
  },
  description: {
    key: "description",
    label: "Description",
  },
  media: {
    key: "media",
    label: "Upload Image",
  },
};

const StatisticsModal = (props) => {
  const { data, template, modalState, setModalState, pushNotif } = props;

  const showImage = StatisticsConfig.showImageInTemplates.includes(template);
  const maxNumber = StatisticsConfig.maxTitleLength[template];
  const maxDescription = StatisticsConfig.maxDescLength[template];

  const primaryCallback = ({ data }) => {
    let errObj = {};
    if (!data?.value?.trim()) {
      errObj.value = "Please enter a valid statistic Number";
    } else if (data.value.length > 50) {
      errObj.value = `Statistic Number must be less than 50 characters (Currently ${data.value.length})`;
    }

    if (!data?.description?.trim()) {
      errObj.description = "Please enter a description";
    } else if (data.description.length < 2) {
      errObj.description = `Description must be at least 2 characters long (Currently ${data.description.length})`;
    } else if (data.description.length > 300) {
      errObj.description = `Description must be less than 300 characters (Currently ${data.description.length})`;
    }

    if (showImage) {
      if (!data.media) {
        errObj.media = "Please upload an image";
      }
    }

    setModalState((stateProp) => ({
      ...stateProp,
      error: {
        ...errObj,
      },
    }));

    const errKeys = Object.keys(errObj);
    if (errKeys.length > 0) {
      pushNotif(
        `The following fields have an error: ${errKeys
          .map((e) => errKeyLabels?.[e]?.(data.type) || e)
          .join(", ")}`
      );
    }
    return errKeys.length === 0;
  };

  useEffect(() => {
    setModalState({ data, primaryCallback });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (key, val) => {
    setModalState((stateProp) => ({
      ...stateProp,
      data: { ...stateProp?.data, [key]: val },
      error: { ...stateProp?.error, [key]: null },
    }));
  };

  const statisticsData = modalState?.data;

  return (
    <div className={style.root}>
      <div className={style.body}>
        {showImage ? (
          <div className={baseStyle.fieldContainer}>
            <div
              className={`${baseStyle.label} ${
                showImage ? baseStyle.required : ""
              }`}
            >
              {formData.media.label}
            </div>
            <div className={baseStyle.field}>
              <ImageUploader
                uploadUrl={statisticsData?.media}
                setUploadUrl={(v) => {
                  handleChange(formData.media.key, v);
                }}
                hoverText="Upload Image"
                fullWidth={200}
                width={200}
                height={234}
                borderRadius="2%"
                className={style.imageUploader}
                query={"Image"}
                id="statistics_modal_image"
                uploadViaOptimization
                sectionName={IMAGE_SECTION_KEY_PATH.HOME_PAGE}
              />
            </div>
            <div className={baseStyle.modal_error}>
              {modalState?.error?.media || ""}
            </div>
          </div>
        ) : null}

        <div className={baseStyle.fieldContainer}>
          <div className={`${baseStyle.label} ${baseStyle.required}`}>
            {formData.value.label}
          </div>
          <div className={baseStyle.field}>
            <OutlinedInput
              className={baseStyle.input}
              value={statisticsData?.value}
              name={formData.value.key}
              inputProps={{
                maxLength: maxNumber,
              }}
              onChange={(e) => {
                handleChange(formData.value.key, e.target.value);
              }}
            />
          </div>
          <div className={baseStyle.modal_error}>
            {(statisticsData?.value?.length >= maxNumber &&
              `Statistic Number must be less than ${maxNumber} characters (Currently ${statisticsData?.value?.length})`) ||
              modalState?.error?.value ||
              ""}
          </div>
        </div>
        <div className={baseStyle.fieldContainer}>
          <div className={`${baseStyle.label} ${baseStyle.required}`}>
            {formData.description.label}
          </div>
          <div className={baseStyle.field}>
            <OutlinedInput
              className={baseStyle.input}
              value={statisticsData?.description}
              name={formData.description.key}
              multiline
              rows={3}
              inputProps={{
                maxLength: maxDescription,
              }}
              onChange={(e) => {
                handleChange(formData.description.key, e.target.value);
              }}
            />
          </div>
          <div className={baseStyle.modal_error}>
            {(statisticsData?.description?.length >= maxDescription &&
              `Description must be less than ${maxDescription} characters long (Currently ${statisticsData?.description?.length})`) ||
              modalState?.error?.description ||
              ""}
          </div>
        </div>
        {StatisticsConfig.samples?.[template] ? (
          <div className={baseStyle.fieldContainer}>
            <div className={baseStyle.label}>
              Example <Info fontSize="18" />
            </div>
            <div className={`${baseStyle.field} ${style.sampleImageContainer}`}>
              <img
                src={StatisticsConfig.samples?.[template]}
                className={style.sampleImage}
                alt=""
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StatisticsModal;
