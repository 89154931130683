// TODO: change the file name to ThankYou.constants.js
export const THANKYOU_PAGE_TYPES = {
  PURCHASE: { key: "purchase", value: 1 },
  OPTIN: { key: "optin", value: 2 },
};

export const THANKYOU_PAGE_SECTION_SLUGS = {
  CONFIRMATION: "Confirmation",
  REDIRECTION: "Redirection",
  UPSELL: "Upsell",
  TESTIMONIAL: "Testimonials",
};

export const THANKYOU_PAGE_SECTION_CONFIG = {
  [THANKYOU_PAGE_SECTION_SLUGS.CONFIRMATION]: {
    section_name: "Confirmation Section",
  },
  [THANKYOU_PAGE_SECTION_SLUGS.REDIRECTION]: {
    section_name: "Redirection",
  },
  [THANKYOU_PAGE_SECTION_SLUGS.UPSELL]: {
    section_name: "Upsell Section",
  },
  [THANKYOU_PAGE_SECTION_SLUGS.TESTIMONIAL]: {
    section_name: "Testimonials",
  },
};

export const THANKYOU_PAGE_UPDATE_CONFIG = {
  [THANKYOU_PAGE_TYPES.PURCHASE.key]: {
    key: THANKYOU_PAGE_TYPES.PURCHASE.key,
    value: THANKYOU_PAGE_TYPES.PURCHASE.value,
    title: "Thank you page (For purchases)",
    mobileTitle: "For Purchases",
    sections: [
      THANKYOU_PAGE_SECTION_SLUGS.CONFIRMATION,
      THANKYOU_PAGE_SECTION_SLUGS.REDIRECTION,
      THANKYOU_PAGE_SECTION_SLUGS.UPSELL,
      THANKYOU_PAGE_SECTION_SLUGS.TESTIMONIAL,
    ],
  },
  [THANKYOU_PAGE_TYPES.OPTIN.key]: {
    key: THANKYOU_PAGE_TYPES.OPTIN.key,
    value: THANKYOU_PAGE_TYPES.OPTIN.value,
    title: "Thank you page (for opt-ins)",
    mobileTitle: "For Opt-ins",
    sections: [
      THANKYOU_PAGE_SECTION_SLUGS.CONFIRMATION,
      THANKYOU_PAGE_SECTION_SLUGS.REDIRECTION,
      THANKYOU_PAGE_SECTION_SLUGS.TESTIMONIAL,
    ],
  },
};

export const THANKYOU_PAGE_PREVIEW_LAYOUT_TYPES = {
  DESKTOP: "DESKTOP",
  MOBILE: "MOBILE",
};

export const THANKYOU_PAGE_PREVIEW_FRAME_ID = "thankyou_preview_frame";

export const THANKYOU_PAGE_ERROR_TYPES = {
  REQUIRED: "REQUIRED",
  INVALID_URL: "INVALID_URL",
  INVALID: "INVALID",
  INVALID_MOBILE: "INVALID_MOBILE",
  OVERALL: "OVERALL",
};

export const THANKYOU_PAGE_ERROR = {
  [THANKYOU_PAGE_ERROR_TYPES.REQUIRED]: {
    message: "Required.",
  },
  [THANKYOU_PAGE_ERROR_TYPES.INVALID_URL]: {
    message: "Invalid URL",
  },
  [THANKYOU_PAGE_ERROR_TYPES.INVALID]: {
    message: "Invalid Data",
  },
  [THANKYOU_PAGE_ERROR_TYPES.INVALID_MOBILE]: {
    message: "Invalid Contact No.",
  },
  [THANKYOU_PAGE_ERROR_TYPES.OVERALL]: {
    message: "Please fill all mandatory fields.",
  },
};

export const THANKYOU_PAGE_PREVIEW_DUMMY_TITLE = {
  [THANKYOU_PAGE_TYPES.OPTIN.key]: "Thank you",
  [THANKYOU_PAGE_TYPES.PURCHASE.key]: "Thank you",
};

export const THANKYOU_PAGE_CONTENT_BUCKET_NAME =
  process.env.REACT_APP_S3_BUCKET_EXLY_CONTENT;

export const THANKYOU_PAGE_PUBLIC_MEDIA_FOLDER_NAME = "thankyou-page";

export const THANKYOU_PAGE_LEARN_MORE_HREFS = {
  THANK_YOU_PAGE:
    "https://support.exlyapp.com/support/solutions/articles/84000392251-how-to-update-the-thank-you-page-post-purchase-",
};
