import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import style from "./style.module.css";
import baseStyle from "../../../style.module.css";

import {
  GALLERY_TYPES,
  GALLERY_ICON_MAP,
  GalleryConfig,
} from "../../../../../constants";
import { ImageUploader } from "../../../../../widgets";

import { validURL } from "utils/validations";
import { IMAGE_SECTION_KEY_PATH } from "features/Common/modules/ExlyImage/constants/ExlyImage.constants";
const placeholderImage =
  "./assets/images/TemplatePreview/image-uploader-placeholder_250x250.jpg";

const errKeyLabels = {
  media: (dataType) => (dataType === GALLERY_TYPES.VIDEO ? "Video" : "Image"),
  caption: () => "Caption",
};

const formData = {
  imageMedia: {
    key: "media",
    label: "Upload Image",
  },
  videoMedia: {
    key: "media",
    label: "Video URL",
  },
  caption: {
    key: "caption",
    label: "Caption",
  },
};

const GalleryModal = (props) => {
  const {
    data,
    template,
    modalState,
    setModalState,
    LoadingComponent,
    pushNotif,
    loadWithPreview = false,
  } = props;
  const [isPreviewOpen, setPreviewOpen] = useState(!!loadWithPreview);

  const primaryCallback = ({ data }) => {
    let errObj = {};
    if (data.type === GALLERY_TYPES.VIDEO) {
      if (!validURL(data.media)) {
        errObj.media = "Please enter a valid video url";
      }
    } else {
      if (!data.media) {
        errObj.media = "Please upload an image";
      }
    }

    if (data?.caption?.length > 400) {
      errObj.caption = `Caption must be less than 400 characters (Currently ${data.caption.length})`;
    }

    setModalState((stateProp) => ({
      ...stateProp,
      error: {
        ...errObj,
      },
    }));

    const errKeys = Object.keys(errObj);
    if (errKeys.length > 0) {
      pushNotif(
        `The following fields have an error: ${errKeys
          .map((e) => errKeyLabels?.[e]?.(data.type) || e)
          .join(", ")}`
      );
    }
    return errKeys.length === 0;
  };

  useEffect(() => {
    setModalState({ data, primaryCallback });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (key, val) => {
    setModalState((stateProp) => ({
      ...stateProp,
      data: { ...stateProp?.data, [key]: val },
      error: { ...stateProp?.error, [key]: null },
    }));
  };
  const handleTypeChange = (newType) => {
    setModalState((stateProp) => ({
      ...stateProp,
      data: {
        ...stateProp.data,
        type: newType,
        media: data?.uuid && data?.type === newType ? data?.media : "",
      },
      error: null,
    }));
  };

  const galleryData = modalState?.data;

  if (!galleryData?.type) return <LoadingComponent />;

  return (
    <div className={style.root}>
      <div className={style.body}>
        <Tabs
          value={galleryData?.type || GALLERY_TYPES.IMAGE}
          className={style.typeSelector}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => {
            handleTypeChange(v);
          }}
          variant="fullWidth"
        >
          {Object.entries(GALLERY_TYPES).map(([typeString, typeKey]) => (
            <Tab
              key={typeKey}
              value={typeKey}
              label={typeString}
              icon={GALLERY_ICON_MAP[typeKey]()}
              classes={{
                wrapper: style.typeTabWrapper,
                labelIcon: style.typeTabLabel,
              }}
            />
          ))}
        </Tabs>
        <div className={baseStyle.fieldContainer}>
          {galleryData?.type === GALLERY_TYPES.VIDEO ? (
            <>
              <div className={`${baseStyle.label} ${baseStyle.required}`}>
                {formData.videoMedia.label}
              </div>
              <div className={baseStyle.field}>
                <OutlinedInput
                  className={baseStyle.input}
                  value={galleryData?.media}
                  name={formData.videoMedia.key}
                  onChange={(e) => {
                    handleChange(formData.videoMedia.key, e.target.value);
                  }}
                />
              </div>
              {validURL(galleryData?.media) ? (
                <div
                  className={`${style.videoPreviewContainer} ${
                    isPreviewOpen ? style.previewOpen : ""
                  }`}
                >
                  <div
                    className={style.previewCTA}
                    onClick={() => {
                      setPreviewOpen(!isPreviewOpen);
                    }}
                  >
                    Preview&nbsp;
                    {isPreviewOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                  {isPreviewOpen ? (
                    <ReactPlayer
                      width="100%"
                      className={style.previewVideo}
                      key={galleryData?.media}
                      url={galleryData?.media}
                      controls
                    />
                  ) : null}
                </div>
              ) : null}
            </>
          ) : null}

          {galleryData?.type === GALLERY_TYPES.IMAGE ? (
            <>
              <div className={`${baseStyle.label} ${baseStyle.required}`}>
                {formData.imageMedia.label}
              </div>
              <ImageUploader
                uploadUrl={galleryData?.media}
                setUploadUrl={(v) => {
                  handleChange(formData.imageMedia.key, v);
                }}
                hoverText="Upload Image"
                fullWidth={500}
                width={250}
                height={250}
                // Allow for fluid aspect ratio for some templates mentioned in the config
                fluidAspectRatio={GalleryConfig.allowFluidAspectRatio.includes(
                  template
                )}
                borderRadius="2%"
                customDefaultImage={placeholderImage}
                className={style.imageUploader}
                query={"Image"}
                uploadViaOptimization
                sectionName={IMAGE_SECTION_KEY_PATH.HOME_PAGE}
              />
            </>
          ) : null}

          <div className={baseStyle.modal_error}>
            {modalState?.error?.media || ""}
          </div>
        </div>

        <div className={baseStyle.fieldContainer}>
          <div className={baseStyle.label}>{formData.caption.label}</div>
          <div className={baseStyle.field}>
            <OutlinedInput
              className={baseStyle.input}
              value={galleryData?.caption}
              name={formData.caption.key}
              onChange={(e) => {
                handleChange(formData.caption.key, e.target.value);
              }}
            />
          </div>
          <div className={baseStyle.modal_error}>
            {(galleryData?.caption?.length > 400 &&
              `Caption must be less than 400 characters (Currently ${galleryData?.caption?.length})`) ||
              modalState?.error?.caption ||
              ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryModal;
