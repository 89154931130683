import { is_empty } from "utils/validations";
export const validateSectionData = (data, handleError) => {
  let tempError = {};
  const { title } = data || {};
  if (is_empty(title)) {
    tempError.title = true;
  }
  handleError && handleError(tempError);
  if (Object.keys(tempError).length > 0) return false;
  else return true;
};
