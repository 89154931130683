import { appendUrlParams, getRAUrlParams } from "utils/urlUtils";
import {
  THANKYOU_PAGE_PUBLIC_MEDIA_FOLDER_NAME,
  THANKYOU_PAGE_TYPES,
} from "../constants/Thankyou.constants";
import { LISTING_UUID_SEARCH_KEY } from "../pages/ManageThankYouAndUpsell/ManageThankyouAndUpsell.constants";
import { generateCustomPathForFile } from "common/Components/VideoUploader/VideoUploader.utils";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";

export const getThankyouPageUrl = (listingUuid, pageType, options = {}) => {
  let previewPageUrl = "";
  const isPurchasePageType = pageType === THANKYOU_PAGE_TYPES.PURCHASE.key;

  if (isPurchasePageType)
    previewPageUrl = `${getCreatorHostsiteURL().url}/${
      listingUuid /* "f0200c2c-add6-4884-8901-bdcb9ed8c316" */
    }/purchase/thankyou`;
  else
    previewPageUrl = `${getCreatorHostsiteURL().url}/${
      listingUuid /* "f0200c2c-add6-4884-8901-bdcb9ed8c316" */
    }/optin/thankyou`;

  if (options.preview) {
    previewPageUrl = appendUrlParams(previewPageUrl, {
      preview: true,
      ...(isPurchasePageType && { status: 2 }),
    });
  }
  return previewPageUrl;
};

export const openThankyouPreviewPage = (listingUuid, pageType, options) => {
  const previewPageUrl = getThankyouPageUrl(listingUuid, pageType, options);
  if (window) window.open(previewPageUrl, "_blank");
};

export const getPlaceholderImage = ({
  size,
  imageLabel = "Select Image",
  fontSize,
}) =>
  `https://fakeimg.pl/${size}/fff/777/?text=${imageLabel
    .split(" ")
    .join("+")}&font=noto&font_size=${fontSize}`;

export const getListingUuidFromUrlParam = () =>
  getRAUrlParams()?.get(LISTING_UUID_SEARCH_KEY);

export const getCustomRelativeS3Path = (file, contentType, id) => {
  return generateCustomPathForFile(
    file,
    `${THANKYOU_PAGE_PUBLIC_MEDIA_FOLDER_NAME}/${contentType}/${id}`
  );
};
