export const THANKYOU_PAGE_UPDATE_FETCH_SECTIONS_CONFIG =
  "@THANKYOU_PAGE/UPDATE/FETCH_SECTIONS_CONFIG";
export const THANKYOU_PAGE_UPDATE_FETCH_SECTIONS_DATA =
  "@THANKYOU_PAGE/UPDATE/FETCH_SECTIONS_DATA";
export const THANKYOU_PAGE_CREATE = "@THANKYOU_PAGE/UPDATE/CREATE";
export const THANKYOU_PAGE_UPDATE_SET_ID = "@THANKYOU_PAGE/UPDATE/SET_ID";
export const THANKYOU_PAGE_UPDATE_ADD_SECTIONS_DATA =
  "@THANKYOU_PAGE/UPDATE/ADD_SECTIONS_DATA";
export const THANKYOU_PAGE_UPDATE_SET_SECTIONS_HAS_DATA =
  "@THANKYOU_PAGE/UPDATE/SET_SECTIONS_HAS_DATA";
export const THANKYOU_PAGE_UPDATE_SET_SECTIONS_CONFIG =
  "@THANKYOU_PAGE/UPDATE/SET_SECTIONS_CONFIG";
export const THANKYOU_PAGE_UPDATE_SET_SECTIONS_DATA =
  "@THANKYOU_PAGE/UPDATE/SET_SECTIONS_DATA";
export const THANKYOU_PAGE_UPDATE_POST_LIVE_SECTIONS_DATA =
  "@THANKYOU_PAGE/UPDATE/POST_LIVE_SECTIONS_DATA";
