import { THANKYOU_PAGE_PREVIEW_FRAME_ID } from "../constants/Thankyou.constants";
import { takeEvery, call, put, select } from "redux-saga/effects";
import {
  THANKYOU_PAGE_UPDATE_FETCH_SECTIONS_DATA,
  THANKYOU_PAGE_UPDATE_POST_LIVE_SECTIONS_DATA,
  THANKYOU_PAGE_UPDATE_SET_SECTIONS_CONFIG,
  THANKYOU_PAGE_UPDATE_SET_SECTIONS_DATA,
  THANKYOU_PAGE_UPDATE_ADD_SECTIONS_DATA,
  THANKYOU_PAGE_UPDATE_SET_ID,
  THANKYOU_PAGE_CREATE,
  THANKYOU_PAGE_UPDATE_FETCH_SECTIONS_CONFIG,
  THANKYOU_PAGE_UPDATE_SET_SECTIONS_HAS_DATA,
} from "./actions";
import { dataProvider } from "data";
import { THANKYOU_PAGE_API } from "../api/thankyou.api.constants";
import { apiMethods } from "data/api.constants";
import { notification_color_keys } from "utils/hooks";
import { handleDefaultSectionsData } from "../pages/Update/Update.utils";

function* createThankYouPage({ payload }) {
  const { offeringUuid, pageType } = payload;
  const pageAvailabilityData = yield call(
    dataProvider.custom_request,
    THANKYOU_PAGE_API.is_page_available,
    apiMethods.GET,
    { listing_uuid: offeringUuid, thankyou_page_type: pageType.value }
  );
  if (pageAvailabilityData.status === 200) {
    const thankyouPageId = pageAvailabilityData.data.thankyou_page_uuid;
    if (!thankyouPageId) {
      const createData = yield call(
        dataProvider.custom_request,
        THANKYOU_PAGE_API.create,
        apiMethods.POST,
        {
          listing_uuid: offeringUuid,
          thankyou_page_type: pageType.value,
        }
      );
      if (createData?.status === 200) {
        yield put({
          type: THANKYOU_PAGE_UPDATE_SET_ID,
          payload: {
            id: createData.data.thankyou_page_uuid,
            pageType: pageType.key,
          },
        });
      } else {
        throw Error(
          `API Error: ${THANKYOU_PAGE_API.create} at createThankYouPage`
        );
      }
    } else {
      yield put({
        type: THANKYOU_PAGE_UPDATE_SET_ID,
        payload: { id: thankyouPageId, pageType: pageType.key },
      });
    }
  } else {
    throw Error(
      `API Error: ${THANKYOU_PAGE_API.is_page_available} at createThankYouPage`
    );
  }
}

function* fetchThankyouPageSectionsConfig({ payload }) {
  const { thankyouPageUuid } = payload;
  const response = yield call(
    dataProvider.custom_request,
    `${THANKYOU_PAGE_API.get_sections_config}/${thankyouPageUuid}`,
    apiMethods.GET
  );
  if (response?.status === 200) {
    const {
      data: { sections },
    } = response;
    yield put({
      type: THANKYOU_PAGE_UPDATE_SET_SECTIONS_CONFIG,
      payload: { sectionsConfig: sections },
    });
  } else {
    throw Error(
      `API Error: ${THANKYOU_PAGE_API.get_sections_config} at fetchThankyouPageSectionsConfig`
    );
  }
}

function* fetchThankyouPageSectionsData({ payload }) {
  const { thankyouPageUuid, pageType } = payload;
  const response = yield call(
    dataProvider.custom_request,
    `${THANKYOU_PAGE_API.get_sections_data}/${thankyouPageUuid}`,
    apiMethods.GET
  );
  if (response?.status === 200) {
    const {
      data: { sections },
    } = response;
    const finalSections = handleDefaultSectionsData({
      allSectionsData: sections,
      pageType,
    });
    yield put({
      type: THANKYOU_PAGE_UPDATE_SET_SECTIONS_DATA,
      payload: { sectionsData: finalSections },
    });
  } else {
    throw Error(
      `API Error: ${THANKYOU_PAGE_API.get_sections_data} at fetchThankyouPageSectionsData`
    );
  }
}

function* updateHasSectionData({ payload }) {
  const { sectionsData } = payload;
  let sectionsConfig = yield select(
    (state) => state.thankyou.update.sectionsConfig
  );
  sectionsConfig = sectionsConfig.map((section) => {
    const updatedSection = sectionsData.find(
      (_section) => _section.section_uuid === section.uuid
    );
    if (updatedSection && updatedSection.section_data)
      return { ...section, has_section_data: true };
    else if (updatedSection && !updatedSection.section_data)
      return { ...section, has_section_data: false };
    return section;
  });
  yield put({
    type: THANKYOU_PAGE_UPDATE_SET_SECTIONS_HAS_DATA,
    payload: { sectionsConfig: sectionsConfig },
  });
}

function* addThankyouPageSectionData({ payload }) {
  const { sectionsData, thankyouPageUuid, notify } = payload;
  const response = yield call(
    dataProvider.custom_request,
    THANKYOU_PAGE_API.add_sections_data,
    "POST",
    { section_data: sectionsData, thankyou_page_uuid: thankyouPageUuid }
  );
  if (response?.status == 200) {
    yield updateHasSectionData({ payload });
    notify(response?.message, notification_color_keys.success);
  } else {
    notify("Something Went Wrong", notification_color_keys.error);
    throw Error(
      `API Error: ${THANKYOU_PAGE_API.add_sections_data} at addThankyouPageSectionData`
    );
  }
}

function* updateThankyouPreviewData({ payload }) {
  const { section } = payload;
  const previewIframe = document.getElementById(THANKYOU_PAGE_PREVIEW_FRAME_ID);
  // eslint-disable-next-line no-unused-expressions
  // @ts-ignore
  yield previewIframe?.contentWindow?.postMessage?.({ section }, "*");
}

export default function* thankyouPageSaga() {
  yield takeEvery(THANKYOU_PAGE_CREATE, createThankYouPage);
  yield takeEvery(
    THANKYOU_PAGE_UPDATE_FETCH_SECTIONS_CONFIG,
    fetchThankyouPageSectionsConfig
  );
  yield takeEvery(
    THANKYOU_PAGE_UPDATE_FETCH_SECTIONS_DATA,
    fetchThankyouPageSectionsData
  );
  yield takeEvery(
    THANKYOU_PAGE_UPDATE_ADD_SECTIONS_DATA,
    addThankyouPageSectionData
  );
  yield takeEvery(
    THANKYOU_PAGE_UPDATE_POST_LIVE_SECTIONS_DATA,
    updateThankyouPreviewData
  );
}
