import React from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import PropTypes from "prop-types";

import styled from "@material-ui/core/styles/styled";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import PhotoWidget from "../../../../ui/widgets/photoUploader/PhotoWidget";

import coverPhoto from "../../../../assets/images/select_logo_image.png";

import style from "./style.module.css";

export const SocialInput = styled(OutlinedInput)(
  ({ socialcolor: socialColor }) => ({
    "& fieldset, &:hover fieldset": {
      border: "none",
      borderColor: `${socialColor}88!important`,
    },
    "&:hover fieldset": {
      border: "none",
      borderColor: `${socialColor}!important`,
    },
    "& input": {
      padding: "10px 12px 10px 0!important",
      color: socialColor,
      margin: "0 4px 0 -4px",
    },
  })
);

const getPlaceholderImage = (size, imageLabel = "Select Image") =>
  `https://fakeimg.pl/${size}/fff/777/?text=${imageLabel
    .split(" ")
    .join("+")}&font=noto&font_size=16`;

/**
 * @deprecated use src/ui/widgets/photoUploader/PhotoWidgetV2.js
 */
const ImageUploaderComponent = (props) => {
  const {
    hideRecommendedText = false,
    uploadUrl,
    setUploadUrl,
    hoverText,
    fullWidth,
    width,
    height,
    fluidAspectRatio = false, // Whether to allow the user to decide the aspect ratio
    customRecommendedText, //.Custom helper text for the "Recommended image size"
    borderRadius,
    query, // API Query for the unsplash results
    customDefaultImage, // Custom cover image for when nothing is uploaded
    customImageLabel, // Custom label to show on the image
    className = "", // Any additional css classes to add to the root element
    id = "", // Add an ID if multiple ImageUploader components are being used
    disabled,
    photoWidgetClassName,
    uploadViaOptimization,
    sectionName,
  } = props;

  const baseAspectRatio = width / height;

  const crop = {
    unit: "%",
    width: fullWidth,
    aspect: baseAspectRatio,
  };

  const defaultImage =
    customDefaultImage ||
    getPlaceholderImage(
      `${Math.round(width)}x${Math.round(height)}`,
      customImageLabel
    ) ||
    coverPhoto;

  return (
    // The key=uploadUrl re-renders this on image change so that if the image is removed, the cached image is also removed
    <div
      className={`${style.photoWidgetContainer} ${className || ""}`}
      key={uploadUrl}
    >
      <PhotoWidget
        className={`${style.photoWidget} ${photoWidgetClassName || ""}`}
        query={query || "stock"}
        hoverText={hoverText || "Upload image"}
        getUrl={(firebaseUrl) => {
          setUploadUrl(firebaseUrl);
        }}
        fluidAspectRatio={fluidAspectRatio}
        key={uploadUrl}
        crop={crop}
        width={width}
        height={height}
        radius={borderRadius || "0%"}
        image={uploadUrl ? uploadUrl : defaultImage}
        id={id}
        disabled={disabled}
        uploadViaOptimization={uploadViaOptimization}
        sectionName={sectionName}
      />
      {!hideRecommendedText && (
        <div className={style.photoWidgetText}>
          {customRecommendedText || (
            <>
              Recommended format: {fullWidth}&nbsp;&times;&nbsp;
              {Math.round(fullWidth / baseAspectRatio)} pixels (PNG or JPG)
            </>
          )}
        </div>
      )}
    </div>
  );
};

ImageUploaderComponent.propTypes = {
  hideRecommendedText: PropTypes.bool,
  id: PropTypes.string,
  uploadUrl: PropTypes.string,
  setUploadUrl: PropTypes.func,
  hoverText: PropTypes.string,
  fullWidth: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  borderRadius: PropTypes.string,
  query: PropTypes.string,
  customDefaultImage: PropTypes.string,
  className: PropTypes.string,
  fluidAspectRatio: PropTypes.string,
  customRecommendedText: PropTypes.string,
  customImageLabel: PropTypes.string,
  photoWidgetClassName: PropTypes.string,
  uploadViaOptimization: PropTypes.bool,
  sectionName: PropTypes.string,
};

/**
 * @deprecated use src/ui/widgets/photoUploader/PhotoWidgetV2.js
 */
export const ImageUploader = ImageUploaderComponent;

export const RichTextInput = (props) => {
  const { value, onChange, readOnly } = props;
  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      className={style.richTextEditor}
      readOnly={readOnly}
    />
  );
};

RichTextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export const ErrorText = (props) => {
  const { errorState, fieldKey } = props;

  const show_error =
    errorState?.[fieldKey]?.showError && errorState?.[fieldKey]?.error?.length;

  return (
    <div
      className={
        (errorState?.[fieldKey]?.isSuccess && style.success) ||
        (errorState?.[fieldKey]?.isInfo && style.infoText) ||
        `${style.error} ${show_error ? "mb-1" : ""}`
      }
    >
      {show_error ? errorState?.[fieldKey]?.error : <>&nbsp;</>}
    </div>
  );
};

ErrorText.propTypes = {
  errorState: PropTypes.object,
  fieldKey: PropTypes.string,
};
