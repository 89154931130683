import React, { useEffect } from "react";

import { OutlinedInput } from "@material-ui/core";
import Info from "@material-ui/icons/Info";

import style from "./style.module.css";
import baseStyle from "../../../style.module.css";

import { FeaturesConfig } from "../../../../../constants";
import { ImageUploader } from "../../../../../widgets";
import { Tooltip } from "@my-scoot/component-library-legacy";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { IMAGE_SECTION_KEY_PATH } from "features/Common/modules/ExlyImage/constants/ExlyImage.constants";

const errKeyLabels = {
  title: () => "Title",
  description: () => "Description",
  media: () => "Image",
};

const formData = {
  title: {
    key: "title",
    label: "Title",
  },
  description: {
    key: "description",
    label: "Description",
  },
  imageMedia: {
    key: "media",
    label: "Upload Image",
  },
  ctaText: {
    key: "cta_text",
    label: "get in touch button text",
  },
};

const FeatureModal = (props) => {
  const { data, template, modalState, setModalState, pushNotif } = props;

  const showImage = FeaturesConfig.showImageInTemplates.includes(template);

  const primaryCallback = ({ data }) => {
    let errObj = {};
    if (!data?.title?.trim()) {
      errObj.title = "Please enter a valid title";
    } else if (data.title.length > FeaturesConfig.maxTitleLength[template]) {
      errObj.title = `Title must be less than 50 characters (Currently ${data.title.length})`;
    }

    if (!data?.description?.trim()) {
      errObj.description = "Please enter a valid description";
    } else if (data.description.length < 2) {
      errObj.description = `Description must be at least 2 characters long (Currently ${data.description.length})`;
    } else if (
      data.description.length > FeaturesConfig.maxDescLength[template]
    ) {
      errObj.description = `Description must be less than ${FeaturesConfig.maxDescLength[template]} characters (Currently ${data.description.length})`;
    }

    if (showImage) {
      if (!data.media) {
        errObj.media = "Please upload an image";
      }
    }

    setModalState((stateProp) => ({
      ...stateProp,
      error: {
        ...errObj,
      },
    }));

    const errKeys = Object.keys(errObj);
    if (errKeys.length > 0) {
      pushNotif(
        `The following fields have an error: ${errKeys
          .map((e) => errKeyLabels?.[e]?.(data.type) || e)
          .join(", ")}`
      );
    }
    return errKeys.length === 0;
  };

  useEffect(() => {
    setModalState({ data, primaryCallback });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (key, val) => {
    setModalState((stateProp) => ({
      ...stateProp,
      data: { ...stateProp?.data, [key]: val },
      error: { ...stateProp?.error, [key]: null },
    }));
  };

  const featuresData = modalState?.data;

  return (
    <div className={style.root}>
      <div className={style.body}>
        {showImage ? (
          <div className={baseStyle.fieldContainer}>
            <div
              className={`${baseStyle.label} ${
                showImage ? baseStyle.required : ""
              }`}
            >
              {formData.imageMedia.label}
            </div>
            <div className={baseStyle.field}>
              <ImageUploader
                uploadUrl={featuresData?.media}
                setUploadUrl={(v) => {
                  handleChange(formData.imageMedia.key, v);
                }}
                hoverText="Upload Image"
                fullWidth={150}
                width={125}
                height={125}
                borderRadius="100%"
                className={style.imageUploader}
                query={"Image"}
                id="features_modal_image"
                uploadViaOptimization
                sectionName={IMAGE_SECTION_KEY_PATH.HOME_PAGE}
              />
            </div>
            <div className={baseStyle.modal_error}>
              {modalState?.error?.media || ""}
            </div>
          </div>
        ) : null}

        <div className={baseStyle.fieldContainer}>
          <div className={`${baseStyle.label} ${baseStyle.required}`}>
            {formData.title.label}
          </div>
          <div className={baseStyle.field}>
            <OutlinedInput
              className={baseStyle.input}
              value={featuresData?.title}
              name={formData.title.key}
              inputProps={{
                maxLength: 200,
              }}
              onChange={(e) => {
                handleChange(formData.title.key, e.target.value);
              }}
            />
          </div>
          <div className={baseStyle.modal_error}>
            {(featuresData?.title?.length >
              FeaturesConfig.maxTitleLength[template] &&
              `Title must be less than ${FeaturesConfig.maxTitleLength[template]} characters (Currently ${featuresData?.title?.length})`) ||
              modalState?.error?.title ||
              ""}
          </div>
        </div>
        <div className={baseStyle.fieldContainer}>
          <div className={`${baseStyle.label} ${baseStyle.required}`}>
            {formData.description.label}
          </div>
          <div className={baseStyle.field}>
            <OutlinedInput
              className={baseStyle.input}
              value={featuresData?.description}
              name={formData.description.key}
              multiline
              rows={3}
              onChange={(e) => {
                handleChange(formData.description.key, e.target.value);
              }}
            />
          </div>
          <div className={baseStyle.modal_error}>
            {(featuresData?.description?.length >
              FeaturesConfig.maxDescLength[template] &&
              `Description must be less than ${FeaturesConfig.maxDescLength[template]} characters long (Currently ${featuresData?.description?.length})`) ||
              modalState?.error?.description ||
              ""}
          </div>
        </div>
        {FeaturesConfig.enableGetInTouch.includes(template) ? (
          <>
            <div className={`${baseStyle.label}`}>
              {formData.ctaText.label}
              <Tooltip
                title="This button will open the 'Get in touch' form, but you can customize the button text for each feature you add."
                color="primary"
                arrow
              >
                <InfoOutlinedIcon className={baseStyle.tooltip} />
              </Tooltip>
            </div>
            <div className={baseStyle.field}>
              <OutlinedInput
                className={baseStyle.input}
                value={featuresData?.cta_text}
                name={formData.ctaText.key}
                onChange={(e) => {
                  handleChange(formData.ctaText.key, e.target.value);
                }}
              />
            </div>
          </>
        ) : null}
        {FeaturesConfig.samples?.[template] ? (
          <div className={baseStyle.fieldContainer}>
            <div className={baseStyle.label}>
              Example <Info fontSize="18" />
            </div>
            <div className={`${baseStyle.field} ${style.sampleImageContainer}`}>
              <img
                src={FeaturesConfig.samples?.[template]}
                className={style.sampleImage}
                alt=""
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FeatureModal;
