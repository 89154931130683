import { TEMPLATE_SELECT_TAB_KEYS } from "../constants";
import {
  EXLY_WEBPAGE_DETAILS__SET_REORDERING_TYPE,
  EXLY_WEBPAGE_DETAILS__REQUEST_LISTINGS,
  EXLY_WEBPAGE_DETAILS__SET_LISTINGS,
  EXLY_WEBPAGE_DETAILS__STOP_PROCESSING,
  EXLY_WEBPAGE_DETAILS__SET_SELECTED_SKU,
  EXLY_WEBPAGE_DETAILS__UPDATE_SKU_NAME,
  EXLY_WEBPAGE_DETAILS__SET_KEYWORDS,
  EXLY_WEBPAGE_DETAILS__FETCH_KEYWORDS,
  EXLY_WEBPAGE_DETAILS__SET_SELECTED_KEYWORD,
  EXLY_WEBPAGE_DETAILS__SET_SAVE_CTA,
  EXLY_WEBPAGE_DETAILS__REFETCH_LISTINGS,
  EXLY_WEBPAGE_DETAILS__REFETCH_KEYWORDS,
  EXLY_WEBPAGE_DETAILS__SET_PREPEND_PLAN,
  EXLY_WEBPAGE_DETAILS__SET_SELECTED_ENTITY,
  EXLY_WEBPAGE_DETAILS__SET_KEYWORD_CATEGORY,
  EXLY_WEBPAGE_DETAILS__SET_SAVED_REORDER_TYPE,
  EXLY_WEBPAGE_DETAILS__START_UPDATING,
  EXLY_WEBPAGE_DETAILS__UPDATE_TEMPLATE_LIST,
  EXLY_WEBPAGE_DETAILS__SET_SELECTED_TEMPLATE,
  EXLY_WEBPAGE_DETAILS__SET_TEMPLATE_TAB,
} from "./actions";

const initialState = {
  selected_reordering_type: null,
  saved_reordering_type: null,
  selected_sku: null,
  selected_entity: null,
  selected_keyword: null,
  listings: null,
  processing: true,
  updating: false,
  keywords: [],
  save_cta_text: "",
  handleSaveCta: null,
  webpage_templates: [],
  selected_template: null,
  open_template_tab: TEMPLATE_SELECT_TAB_KEYS.RECOMMENDED,
};

const reducer = (state = initialState, data) => {
  switch (data.type) {
    case EXLY_WEBPAGE_DETAILS__SET_SAVE_CTA: {
      return {
        ...state,
        save_cta_text: data.save_cta_text,
        handleSaveCta: data.handleSaveCta,
      };
    }
    case EXLY_WEBPAGE_DETAILS__SET_REORDERING_TYPE: {
      return {
        ...state,
        selected_reordering_type: data.selected_reordering_type,
      };
    }
    case EXLY_WEBPAGE_DETAILS__SET_SELECTED_SKU: {
      return { ...state, selected_sku: data.sku };
    }
    case EXLY_WEBPAGE_DETAILS__SET_SELECTED_ENTITY: {
      return { ...state, selected_entity: data.payload };
    }
    case EXLY_WEBPAGE_DETAILS__UPDATE_SKU_NAME: {
      let listings_data = state.listings;
      listings_data.listings_data.sku_map.listings[state.selected_sku] =
        data.sku_name;
      return { ...state, listings: listings_data, updating: true };
    }
    case EXLY_WEBPAGE_DETAILS__REQUEST_LISTINGS: {
      return { ...state, processing: true };
    }
    case EXLY_WEBPAGE_DETAILS__REFETCH_LISTINGS: {
      return {
        ...state,
        processing: "processing" in data ? data.processing : true,
        listings: [],
      };
    }
    case EXLY_WEBPAGE_DETAILS__REFETCH_KEYWORDS: {
      return { ...state, processing: true, updating: true, keywords: [] };
    }
    case EXLY_WEBPAGE_DETAILS__FETCH_KEYWORDS: {
      return { ...state, processing: true };
    }
    case EXLY_WEBPAGE_DETAILS__SET_LISTINGS: {
      return { ...state, listings: data.payload, processing: false };
    }
    case EXLY_WEBPAGE_DETAILS__SET_KEYWORDS: {
      return { ...state, keywords: data.payload, processing: false };
    }
    case EXLY_WEBPAGE_DETAILS__SET_SELECTED_KEYWORD: {
      return { ...state, selected_keyword: data.payload };
    }
    case EXLY_WEBPAGE_DETAILS__SET_KEYWORD_CATEGORY: {
      let listings_data = state.listings;
      listings_data.categorise_by_keyword = data.payload;
      return { ...state, listings_data: listings_data };
    }
    case EXLY_WEBPAGE_DETAILS__SET_PREPEND_PLAN: {
      let listings_data = state.listings;
      listings_data.prepend_plans = data.payload;
      return { ...state, listings_data: listings_data };
    }
    case EXLY_WEBPAGE_DETAILS__STOP_PROCESSING: {
      return { ...state, processing: false, updating: false };
    }
    case EXLY_WEBPAGE_DETAILS__SET_SAVED_REORDER_TYPE: {
      return { ...state, saved_reordering_type: data.payload };
    }
    case EXLY_WEBPAGE_DETAILS__START_UPDATING: {
      return { ...state, updating: true };
    }
    case EXLY_WEBPAGE_DETAILS__UPDATE_TEMPLATE_LIST: {
      return { ...state, webpage_templates: data.payload.list || [] };
    }

    case EXLY_WEBPAGE_DETAILS__SET_SELECTED_TEMPLATE: {
      return { ...state, selected_template: data.payload.key };
    }
    case EXLY_WEBPAGE_DETAILS__SET_TEMPLATE_TAB: {
      return { ...state, open_template_tab: data.payload.key };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
