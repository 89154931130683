import { getPlaceholderImage } from "ui/pages/ThankYou/utils";

export const UPSELL_SELECT_OFFERING_INFO = `You can only upsell one offering through ‘Live Event Upsells’ and ‘Thank You’ page.`;
export const DEFAULT_OFFER_TEXT_PLACEHOLDER = `Eg, Grab now for just ₹99!`;
export const DEFAULT_OFFERING_PRICE_PLACEHOLDER = `Enter offering price`;
export const DEFAULT_INTL_PRICING_CHECKBOX_LABEL = `I want to add a different price for International Customers`;
export const DEFAULT_INTL_PRICING_INPUT_PLACEHOLDER = `Enter offering price in USD`;

export const UPSELL_MEDIA_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
};

export const UPSELL_MEDIA_PLACEHOLDERS = {
  IMAGE: "./assets/vectors/ThankYouPage/defaultImagePlaceholder.svg",
  VIDEO: "./assets/vectors/ThankYouPage/defaultVideoPlaceholder.svg",
};

export const UPSELL_MEDIA_SELECT_OPTIONS = {
  [UPSELL_MEDIA_TYPES.IMAGE]: {
    id: UPSELL_MEDIA_TYPES.IMAGE,
    label: "Image",
    value: UPSELL_MEDIA_TYPES.IMAGE,
  },
  [UPSELL_MEDIA_TYPES.VIDEO]: {
    id: UPSELL_MEDIA_TYPES.VIDEO,
    label: "Video",
    value: UPSELL_MEDIA_TYPES.VIDEO,
  },
};

export const UPSELL_NONE_OFFERING_SELECT_OPTION = {
  value: "none",
  label: "None",
  id: "None",
  title: "None",
  price_per_head: "",
  type: 20,
  cover_image: getPlaceholderImage({
    size: 70,
    imageLabel: "No Upsell",
    fontSize: 10,
  }),
  currency: "Do not want to Upsell",
  description: "Do not want to Upsell",
};

export const UPSELL_FIELDS_DEFAULT_DATA = {
  title: "You might like this too",
  subTitle: "A one time offer that won’t ever come back again.",
  description:
    "You will never be able to get this at this price. If you miss it now, you miss it forever!",
  successActionText: "Buy Now",
  failureActionText: "No, thanks",
  offerText: "Grab your offer now!",
};
