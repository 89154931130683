export const EXLY_WEBPAGE_DETAILS__SET_REORDERING_TYPE =
  "@EXLY_WEBPAGE_DETAILS/SET_REORDERING_TYPE";
export const EXLY_WEBPAGE_DETAILS__SET_SELECTED_SKU =
  "@EXLY_WEBPAGE_DETAILS/SET_SELECTED_SKU";
export const EXLY_WEBPAGE_DETAILS__SET_SELECTED_ENTITY =
  "@EXLY_WEBPAGE_DETAILS/SET_SELECTED_ENTITY";
export const EXLY_WEBPAGE_DETAILS__REQUEST_LISTINGS =
  "@EXLY_WEBPAGE_DETAILS/REQUEST_LISTINGS";
export const EXLY_WEBPAGE_DETAILS__SET_LISTINGS =
  "@EXLY_WEBPAGE_DETAILS/SET_LISTINGS";
export const EXLY_WEBPAGE_DETAILS__STOP_PROCESSING =
  "@EXLY_WEBPAGE_DETAILS/STOP_PROCESSING";
export const EXLY_WEBPAGE_DETAILS__UPDATE_SKU_NAME =
  "@EXLY_WEBPAGE_DETAILS/UPDATE_SKU_NAME";
export const EXLY_WEBPAGE_DETAILS__FETCH_KEYWORDS =
  "@EXLY_WEBPAGE_DETAILS/FETCH_KEYWORDS";
export const EXLY_WEBPAGE_DETAILS__SET_KEYWORDS =
  "@EXLY_WEBPAGE_DETAILS/SET_KEYWORDS";
export const EXLY_WEBPAGE_DETAILS__SET_SELECTED_KEYWORD =
  "@EXLY_WEBPAGE_DETAILS/SET_SELECTED_KEYWORD";
export const EXLY_WEBPAGE_DETAILS__SET_SAVE_CTA =
  "@EXLY_WEBPAGE_DETAILS/SET_SAVE_CTA";
export const EXLY_WEBPAGE_DETAILS__REFETCH_LISTINGS =
  "@EXLY_WEBPAGE_DETAILS/REFETCH_LISTINGS";
export const EXLY_WEBPAGE_DETAILS__REFETCH_KEYWORDS =
  "@EXLY_WEBPAGE_DETAILS/REFETCH_KEYWORDS";
export const EXLY_WEBPAGE_DETAILS__SET_PREPEND_PLAN =
  "@EXLY_WEBPAGE_DETAILS/SET_PREPEND_PLAN";
export const EXLY_WEBPAGE_DETAILS__SET_KEYWORD_CATEGORY =
  "@EXLY_WEBPAGE_DETAILS/SET_KEYWORD_CATEGORY";
export const EXLY_WEBPAGE_DETAILS__GET_SAVED_REORDER_TYPE =
  "@EXLY_WEBPAGE_DETAILS/GET_SAVED_REORDER_TYPE";
export const EXLY_WEBPAGE_DETAILS__SET_SAVED_REORDER_TYPE =
  "@EXLY_WEBPAGE_DETAILS/SET_SAVED_REORDER_TYPE";
export const EXLY_WEBPAGE_DETAILS__START_UPDATING =
  "@EXLY_WEBPAGE_DETAILS/START_UPDATING";

export const EXLY_WEBPAGE_DETAILS__UPDATE_TEMPLATE_LIST =
  "@EXLY_WEBPAGE_DETAILS/UPDATE_TEMPLATE_LIST";
export const EXLY_WEBPAGE_DETAILS__SET_SELECTED_TEMPLATE =
  "@EXLY_WEBPAGE_DETAILS/SET_SELECTED_TEMPLATE";
export const EXLY_WEBPAGE_DETAILS__SET_TEMPLATE_TAB =
  "@EXLY_WEBPAGE_DETAILS/SET_TEMPLATE_TAB";
